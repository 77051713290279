import React, { Component } from "react";
import {
  Table,
  Empty,
  Card,
  Button,
  Tag,
  Modal,
  Form,
  Radio,
  Checkbox,
  Input,
  Notification,
  Link,
  Steps,
  Typography,
  Spin,
} from "@arco-design/web-react";
import { formatMoney, hidePhone } from "common/utils";
import history from "common/utils/history";
import {
  invoice_detail,
  invoice_cancel,
  delete_invoice,
  editReceiveinfo,
} from "api/apis";
import CustomSteps from "components/CustomSteps";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Step = Steps.Step;
class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: null,
      userinfodata:JSON.parse(localStorage.getItem("userinfo"))
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null,
      order_list: [],
      loading: false,
      no_data: false,
      InvoiceTypeOptions: {
        1: "增值税普通发票",
        2: "增值税专用发票",
      },
      columns: [
        {
          title: "订单号",
          dataIndex: "order_sn",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },

        {
          title: "服务产品",
          dataIndex: "label",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
        {
          title: "订单时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      Modalvisible: false,
      invoice_progress: {},
      receive_progress: {},
      receive_info: {},
      record:
        localStorage.getItem("record") &&
        JSON.parse(localStorage.getItem("record")),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getinit();
  }
  UNSAFE_componentDidUpdate() {}
  getinit() {
    this.getinvoice_list();
  }
  getinvoice_list() {
    let show_id = this.state.record?.show_id;
    invoice_detail({ show_id: show_id }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        const newProgress = {
          ...list.invoice_progress,
          progress:[
            ...list.invoice_progress.progress,
            {
              "title": "已开票",
              "status": 3,
              "created_at": null
            }
          ]
          }
        this.setState({
          data: list,
          loading: false,
          loadingbody: false,
          order_list: list.order_list,
          invoice_progress: newProgress,
          receive_progress: list.receive_progress,
          receive_info: list.receive_info,
          no_data: list.order_list.length > 0 ? false : true,
        });
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  setModalvisible(value, type) {
    this.setState({
      Modalvisible: type === 2 ? value : false,
    });
    setTimeout(() => {
      if (this.state.Modalvisible) {
        this.formRef.setFieldsValue(this.state.receive_info);
      }
    }, 100);
  }
  seteditReceiveinfo(obj) {
    obj.show_id = this.state.record.show_id;
    editReceiveinfo(obj).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "保存成功！" });
        this.getinit();
        this.setModalvisible(false);
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  formRefDate() {
    this.formRef
      .validate()
      .then((res) => {
        this.setState({
          confirmLoading: true,
          isedit: false,
        });
        let obj = res;
        // console.log(obj, "obj");
        this.seteditReceiveinfo(obj);
      })
      .catch((error) => {
        let errorslist = error.errors;
        let message = "";
        Object.keys(errorslist ? errorslist : []).map((item, i) => {
          if (errorslist[item].requiredError && i === 0) {
            message = errorslist[item].message;
          }
        });
        Notification.error({ content: message });
      });
  }
  formRefcancel() {
    this.setModalvisible(false);
    this.formRef.resetFields();
  }
  formRefedit() {
    this.setState({
      isedit: true,
    });
  }
  goInvoiceinFormation() {
    history.push("/payment/invoiceinformation");
    history.go();
  }
  oninvoice_cancel() {
    invoice_cancel({ show_id: this.state.record.show_id }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "取消成功！" });
        this.getinit();
      } else {
        Notification.success({ content: res.msg });
      }
    });
  }
  ondelete_invoice(res) {
    delete_invoice({ show_id: res.show_id }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "删除成功！" });
        this.getinit();
      } else {
        Notification.success({ content: res.msg });
      }
    });
  }
  onViewDetails() {}
  render() {
    const {
      loadingbody,
      Modalvisible,
      loading,
      columns,
      order_list,
      no_data,
      data,
      receive_info,
      userinfodata,
    } = this.state;
    return (
      <div className="payment-invoicedetail">
        <div className="Header-init">
          <div className="Header-title">查看详情</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {/* {no_data ? (
              ""
            ) : (
              <div className="Table-card">
                <Table
                  virtualized
                  noDataElement={
                    <Empty
                      imgSrc={Emptydata_img}
                      description={"暂无相关数据"}
                    />
                  }
                  no_data={no_data}
                  border={{ wrapper: false, cell: false }}
                  loading={loading}
                  columns={columns}
                  data={order_list}
                  pagination={false}
                />
              </div>
            )} */}
            <div className="invoicedetail-card">
              <div className="invoicedetail-card-head">发票信息</div>
              <div className="invoicedetail-card-body">
                <div className="custom-form">
                  <div className="custom-form-item">
                    <span className="custom-form-lable">抬头类型：</span>
                    <span className="custom-form-value">{userinfodata.group_permission === "PERSONAL" ? "个人" : data.type}</span>
                  </div>
                  <div className="custom-form-item">
                    <span className="custom-form-lable">发票类型：</span>
                    <span className="custom-form-value">
                      {data.invoice_type2}
                    </span>
                  </div>
                  <div className="custom-form-item">
                    <span className="custom-form-lable">发票抬头：</span>
                    <span className="custom-form-value">
                      {data.invoice_name}
                    </span>
                  </div>
                  <div className="custom-form-item">
                    <span className="custom-form-lable">开票金额：</span>
                    <span className="custom-form-value">
                      ¥ {data.total_price ? data.total_price : 0}
                    </span>
                  </div>
                  {/* <div className="custom-form-item">
                    <span className="custom-form-lable">税率：</span>
                    <span className="custom-form-value">
                      {data.tax_point ? data.tax_point : 0}％
                    </span>
                  </div>
                  <div className="custom-form-item">
                    <span className="custom-form-lable">税额：</span>
                    <span className="custom-form-value">
                      ¥ {data.tax_amount ? data.tax_amount : 0}
                    </span>
                  </div> */}
                </div>
                <div className="invoicedetail-card-hr"></div>
                <div className="invoicedetail-card-progress">
                  <div className="invoicedetail-card-title">开票进度</div>
                  <div className="invoicedetail-card-operation">
                    {data.status === 2 ? (
                      <Button
                        className="custom-cancel-button"
                        onClick={this.oninvoice_cancel.bind(this)}
                      >
                        取消
                      </Button>
                    ) : data.status === 3 ? (
                      <>
                        <Button type="outline" className="outline" href={data.invoice_url} target="_blank">
                          下载
                        </Button>
                        {/* {receive_info.receive_address ? (
                          ""
                        ) : (
                          <Button
                            type="outline"
                            style={{ marginLeft: 13 }}
                            className="outline"
                            onClick={this.setModalvisible.bind(this, true, 2)}
                          >
                            邮寄
                          </Button>
                        )} */}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="invoice-progress">
                    {/* list={this.state.invoice_progress?.progress} */}
                    <CustomSteps
                      list={this.state.invoice_progress?.progress}
                      current={this.state.data.status+1}
                    />
                    {this.state.invoice_progress?.invoice_bak ? (
                      <div className="failure-reason">
                        * 失败原因：{this.state.invoice_progress.invoice_bak}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {data.status === 3 ? (
              <div className="invoicedetail-card">
                <div className="invoicedetail-card-head">邮寄信息</div>
                <div className="invoicedetail-card-body">
                  <div className="invoicedetail-card-operation">
                    {this.state.invoice_progress?.current !==
                    this.state.invoice_progress.length ? (
                      <>
                        <Button
                          type="outline"
                          className="outline"
                          onClick={this.setModalvisible.bind(this, true, 2)}
                        >
                          修改
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="custom-form">
                    <div className="custom-form-item">
                      <span className="custom-form-lable">收件人：</span>
                      <span className="custom-form-value">
                        {receive_info.receive_name}
                      </span>
                    </div>
                    <div className="custom-form-item">
                      <span className="custom-form-lable">联系电话：</span>
                      <span className="custom-form-value">
                        {receive_info.receive_phone}
                      </span>
                    </div>
                    <div className="custom-form-item">
                      <span className="custom-form-lable">收件地址：</span>
                      <span className="custom-form-value">
                        {receive_info.receive_address}
                      </span>
                    </div>
                  </div>
                  <div className="invoicedetail-card-hr"></div>
                  <div className="invoicedetail-card-title rowStart">
                    邮寄进度
                    <div className="copyable-info rowStart">
                      {data.courier_company}
                      {data.tracking_no ? (
                        <Typography.Paragraph copyable>
                          <span className="AlibabaPuHuiTi-Medium">
                            {data.tracking_no}
                          </span>
                        </Typography.Paragraph>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="invoice-progress">
                    <CustomSteps
                      list={this.state.receive_progress?.progress}
                      current={this.state.receive_progress?.current}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </>
        )}
        <Modal
          style={{
            width: 559,
          }}
          className="Payment-order mail"
          title="邮寄信息填写"
          visible={Modalvisible}
          footer={null}
          onCancel={() => this.setModalvisible(false)}
        >
          <div style={{ textAlign: "left" }}>
            <Form ref={(ref) => (this.formRef = ref)} layout="vertical">
              <FormItem
                label="收件人姓名"
                field="receive_name"
                rules={[{ required: true, message: "请输入收件人姓名" }]}
              >
                <Input placeholder="" />
              </FormItem>
              <FormItem
                label="联系电话"
                field="receive_phone"
                rules={[{ required: true, message: "请输入联系电话" }]}
              >
                <Input placeholder="" maxLength="11" />
              </FormItem>
              <FormItem
                label="邮寄地址"
                field="receive_address"
                rules={[{ required: true, message: "请输入邮寄地址" }]}
              >
                <Input.TextArea placeholder="" />
              </FormItem>
              <FormItem
                style={{ textAlign: "right", marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  className="custom-cancel-button"
                  onClick={() => {
                    this.formRefcancel();
                  }}
                  style={{ marginRight: 18 }}
                >
                  取消
                </Button>
                <Button
                  className="custom-confirm-button"
                  htmlType="submit"
                  onClick={this.formRefDate.bind(this)}
                >
                  确定
                </Button>
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}
export default InvoiceDetail;
