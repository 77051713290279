import React, { Component } from "react";
import {
  Space,
  Button,
  Statistic,
  Divider,
  Link,
  Spin,
  Switch,
  Radio,
  List,
  Empty,
  Typography,
  Tooltip,
  Modal,
  Notification,
  Input,
  InputNumber,
} from "@arco-design/web-react";
import history from "common/utils/history";
import {
  IconStarFill,
  IconRight,
  IconQuestionCircleFill,
  IconCheck
} from "@arco-design/web-react/icon";
import {
  getenginepackage,
  getuserpackage,
  getbalance,
  open_settlement,
  close_settlement,
  getuserInfo,
  setBalance,
} from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import File from "asserts/File.png";
import Image from "asserts/Image.png";
import Text from "asserts/Text.png";
import PPT from "asserts/PPT.png";
import words_img from "asserts/words_img.png";
import image_img from "asserts/image_img.png";
import document_img from "asserts/document_img.png";
import "./index.scss";
// 封装组件
class Paymentinformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      isautomatic: false,
      available_limit: 0, //可用余额
      balance: 0, //充值余额
      credit_limit: 0, //信用余额
      give_limit: 0, //赠送额度
      used_limit: 0, //使用金额
      amount_in_arrears: 0, //使用金额
      userinfo: {},
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 20,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,

      ModalContactService: false,
      forewarning:'',
      isForewarning:false,
      type:false,
    };
  }
  componentDidMount() {
    // this.getenginepackage();
    this.getbalance();
    //请求用户资源包
    this.setInfo()
  }
  UNSAFE_componentDidUpdate() { }
  getbalance() {
    getbalance().then((res) => {
      if (res.code === 200) {
        this.setState({
          available_limit: res.data.available_limit, //可用余额
          balance: res.data.balance, //充值余额
          credit_limit: res.data.credit_limit, //信用余额
          give_limit: res.data.give_limit, //赠送额度
          used_limit: res.data.used_limit, //使用金额
          amount_in_arrears: res.data.amount_in_arrears, //使用金额
          isautomatic: res.data.settlement_status,
          forewarning: res.data.balance_alert,
          isForewarning: res.data.alert_status,
          type: res.data.alert_status,
        });
      }
    });
  }

  setInfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        this.setState({
          userinfo: res.data,
        });
      }
    });
  }

  onCancelContact(value) {
    const {isForewarning,type} = this.state;
    this.setState({
      ModalContactService: value,
      isForewarning:type
    })
  }
  onRechargen() {
    // 审核状态 PENDING-待审核 APPROVED-已审核 REJECTED-审核不通过 NOT_APPLIED-未申请
    if (this.state.userinfo.group_permission === "PERSONAL") {
      if (this.state.userinfo.identity === 'APPROVED') {
        history.push("/payment/accountrecharge");
        history.go();
      } else {
        history.push("/personalAuthentication");
        history.go();
      }
    } else {
      history.push("/payment/accountrecharge");
      history.go();
    }
  }

  //金额的千分位转化
  formatAmount = (amount) => {
    // 将金额转换为字符串
    let formattedAmount = String(amount);

    // 检查是否存在小数点
    const hasDecimal = formattedAmount.includes('.');

    // 如果没有小数点，则添加 .00
    if (!hasDecimal) {
      formattedAmount += '.00';
    }

    // 将金额按照千位分隔符分组
    formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // 添加货币符号
    formattedAmount = `${formattedAmount}`;

    return formattedAmount;
  };

  //设置余额弹窗确认
  setBalances = () => {
    const {forewarning,isForewarning,type } = this.state;
    this.setState({
      type:isForewarning
    },()=>{
      const param = {
        'balance_alert': this.state.forewarning,
        'type': this.state.type ? 1 : 0
      }
      setBalance(param).then((res) => {
        if (res.code === 200) {
          this.setState({
            ModalContactService: false,
          });
        }
      });
    })
  }

  render() {
    const {
      loadingbody,
      available_limit,
      balance,
      credit_limit,
      give_limit,
      used_limit,
      amount_in_arrears,
      isautomatic,
      ModalContactService,
      forewarning,
      isForewarning,
    } = this.state;
    return (
      <div className="payment-formation-general">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">账户总览</div>
            </div>
            <div className="payment-balance columnSpaceStart">

              <div className="payment-end GroupTable-fixe-end">
                  <div className="reneral-item">
                    <div className="reneral-item-lable">余额：</div>
                    <div className="reneral-item-value"> ¥{this.formatAmount(available_limit)}</div>
                    <div>
                    <Link onClick={()=>this.setState({ModalContactService:true})} style={{cursor:'pointer'}}>余额预警设置</Link>
                    <Tooltip
                      className="monthly-tooltip"
                      color="#FFFFFF"
                      position="top"
                      trigger="hover"
                      content="若您的账户余额低于设置阈值时，我们将发送短信通知您。为保障账户服务可用性，建议开启预警。"
                    >
                      <IconQuestionCircleFill style={{color: "#515C6B",cursor:'pointer'}} />
                    </Tooltip>
                    </div>
                  </div>
                  <div className="reneral-item">
                    <div className="reneral-item-lable">赠送金额：</div>
                    <div className="reneral-item-value"> ¥{this.formatAmount(give_limit)}</div>
                  </div>
                  <div className="reneral-item">
                    <div className="reneral-item-lable">累计充值：</div>
                    <div className="reneral-item-value"> ¥{this.formatAmount(balance)}</div>
                  </div>
                  <div className="reneral-item">
                    <div className="reneral-item-lable">总消费：</div>
                    <div className="reneral-item-value"> ¥{this.formatAmount(used_limit)}</div>
                  </div>

                <div>

                </div>
              </div>
              <div className="payment-prcieDetail">
         
                <Button
                    type="primary"
                    className="btn-Recharge"
                    onClick={this.onRechargen.bind(this)}
                  >
                    去充值
                  </Button>
              </div>
            </div>


          </>
        )}
        <Modal
          visible={ModalContactService}
          title={<div style={{ textAlign: 'left' }}>余额预警</div>}
          // footer={null}
          className="Contactmodal general-modal"
          maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
          onCancel={() => {
            this.onCancelContact(false)
          }}
          onOk={()=>{
            this.setBalances()
          }}
        >
            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                  <div className="general-modal-item">
                    <div className="general-text">余额预警:</div><Switch checked={isForewarning} onChange={(value)=>this.setState({isForewarning:value}) }/>
                  </div>
                  <div className="general-modal-item">
                    <div className="general-text">预警阈值（元）:</div><InputNumber style={{ width: 170 }} value={forewarning}  placeholder='00.00' className="general-modal-input"
                     onChange={(e) => this.setState({forewarning:e}) } />
                  </div>
              </div>
            </div>
        </Modal>
      </div>
    );
  }
}
export default Paymentinformation;
