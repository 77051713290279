import React, { Component } from "react";
import { Table, Empty, Card, Spin,Button, DatePicker, Select, Dropdown, Menu, Typography, Modal, Radio} from "@arco-design/web-react";
import { getCurrentDate } from "common/utils";
import Emptydata_img from "asserts/Emptydata_img.svg";
import { getbalance,getApiDetail } from "api/apis";
import {
  IconDownload,
  IconCalendar,
  IconDown,
  IconSync
} from "@arco-design/web-react/icon";
import "./index.scss";

const RangePicker = DatePicker;
const RadioGroup = Radio.Group;
// 封装组件
class Paymentdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      keyword: "",
      checkbox: false,
      selectedRowKeys: [],
      rangeValue: ["", ""],
      ishowdata:false,
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      downVisible:false,
      timeRadio:'a',
      deriveValue:["", ""],
      trading:'全部',
      apply:'全部',
      columns: [
        {
          title: "编号",
          dataIndex: "rid",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },
        {
          title: "交易时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "应用类型",
          dataIndex: "app_type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "应用名称",
          dataIndex: "app_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "接口名称",
          dataIndex: "api_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "交易类型",
          dataIndex: "type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record) => (
            <span>
              {col === "REAL-TIME"
                ? "消费"
                : col === "REFUND"
                ? "提现"
                : col === "TOP_UP"
                ? "充值"
                : ""}
            </span>
          ),
        },

        // {
        //   title: "流水类型",
        //   dataIndex: "type",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        //   render: (col, record) => (
        //     <span>{col === "IN" ? "进账" : col === "OUT" ? "出账" : ""}</span>
        //   ),
        // },
        {
          title: "金额",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        // {
        //   title: "余额",
        //   dataIndex: "amount",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },

      ],
    };
  }

  componentDidMount() {
    this.getbalance();
    document.addEventListener("click", this.hideAllMenu);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.hideAllMenu);
  }
  UNSAFE_componentDidUpdate() {}
  getbalance() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      // type: "list",
    };
    // getbalance getApiDetail
    getApiDetail(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: this.state.data.concat(...list.data),
          loading: false,
          loadingbody: false,
          no_data: list.all_count > 0 ? false : true,
        });
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getbalance();
  }
  hideAllMenu = () => {
    if (this.state.ishowdata) {
      this.setState({
        ishowdata: !this.state.ishowdata,
      });
    }
  };
  openDate(event) {
    this.stopPropagation(event);
    this.setState({
      ishowdata: !this.state.ishowdata,
    });
  }
  onClickdate(event) {
    this.stopPropagation(event);
  }
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation();
  }
  setDates2(dateString) {
    this.setState({
      deriveValue: dateString,
    });
  }
  onChange2(dateString) {
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      deriveValue: dateString,
      value: "",
    });
  }
  setDates(dateString) {
    this.setState({
      rangeValue: dateString,
    });
  }
  onChange(dateString) {
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      rangeValue: dateString,
      value: "",
    });
  }
  onOk(dateString, date) {
    //console.log(date, "event");
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      postrangeValue: dateString,
      value: "",
      ishowdata: !this.state.ishowdata,
      pagination: packagePagination,
    });
    // this.getpackage();
  }
  render() {
    const { loadingbody, loading, columns, data, pagination, no_data, rangeValue, ishowdata,trading,apply,downVisible,timeRadio,deriveValue } =
      this.state;
    return (
      <div className="payment-details">
        <div className="Header-init">
          <div className="Header-title">余额明细</div>
        </div>
        <div>
          <div className="over-header">
            <div className="over-header-left">

              <div
                  className="DatePicker-RangePicker"
                  onClick={this.onClickdate.bind(this)}
                >
                    <DatePicker.RangePicker
                      showTime
                      prefix={<IconCalendar />}
                      className="RangePicker-box"
                      style={{ width: 240 }}
                      format='YYYY-MM-DD'
                      value={rangeValue}
                      onSelect={(valueString, value) => {
                        this.setDates(value);
                      }}
                      onChange={(v) => this.onChange(v)}
                      onOk={this.onOk.bind(this)}
                      disabledDate={(current) => {
                        if (rangeValue && rangeValue.length && current) {
                          const tooLate =
                            rangeValue[0] &&
                            Math.abs(current.diff(rangeValue[0], "day")) > 28;
                          const tooEarly =
                            rangeValue[1] &&
                            Math.abs(rangeValue[1].diff(current, "day")) > 28;
                          return tooEarly || tooLate;
                        }
                        return false;
                      }}
                    />
                    <div></div>
              </div>
                  {/* <Dropdown
                  triggerProps={{ autoAlignPopupWidth: true }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  droplist={
                    <Menu className="header-menu">
                      <Menu.Item key="1" onClick={()=> this.setState({trading:'全部'})}>
                          全部
                      </Menu.Item>   
                        <Menu.Item
                          key="3"
                          onClick={()=> this.setState({trading:'消费'})}
                        >
                          消费
                        </Menu.Item>
                      <Menu.Item
                        key="4"
                        onClick={()=> this.setState({trading:'充值'})}
                      >
                          充值
                      </Menu.Item>
                    </Menu>
                  }
                  position="br"
                >
                  <Button
                    className="custom-select"
                    id="infoselect"
                    style={{ height: 43, padding: '0 13px', borderRadius: 14 }}
                  >
                    <div className="rowCenter">
                      <span className="custom-type">交易类型</span>
                      <span className="custom-shile"></span>
                      <Typography.Text ellipsis className="username-ellipsis">
                        {trading}
                      </Typography.Text>
                    </div>

                    <IconDown style={{ marginLeft: 20 }} />
                  </Button>
                </Dropdown> */}

                <Dropdown
                  triggerProps={{ autoAlignPopupWidth: true }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  droplist={
                    <Menu className="header-menu">
                      <Menu.Item key="1" onClick={()=> this.setState({apply:'全部'})}>
                          全部
                      </Menu.Item>   
                        <Menu.Item
                          key="2"
                          onClick={()=> this.setState({apply:'智能PPT'})}
                        >
                          智能PPT
                        </Menu.Item>
                      <Menu.Item
                        key="3"
                        onClick={()=> this.setState({apply:'智能PPT(SaaS版)'})}
                      >
                          智能PPT(SaaS版)
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        onClick={()=> this.setState({apply:'智能简历'})}
                      >
                          智能简历
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        onClick={()=> this.setState({apply:'智能简历(SaaS版)'})}
                      >
                          智能简历(SaaS版)
                      </Menu.Item>
                      <Menu.Item
                        key="6"
                        onClick={()=> this.setState({apply:'智能营销'})}
                      >
                          智能营销
                      </Menu.Item>
                      <Menu.Item
                        key="7"
                        onClick={()=> this.setState({apply:'智能合同'})}
                      >
                          智能合同
                      </Menu.Item>
                    </Menu>
                  }
                  position="br"
                >
                  <Button
                    className="custom-select"
                    id="infoselect"
                    style={{ height: 43, padding: '0 13px', borderRadius: 14 }}
                  >
                    <div className="rowCenter">
                      <span className="custom-type">应用类型</span>
                      <span className="custom-shile"></span>
                      <Typography.Text ellipsis className="username-ellipsis">
                        {apply}
                      </Typography.Text>
                    </div>

                    <IconDown style={{ marginLeft: 20 }} />
                  </Button>
                </Dropdown>

            </div>

            <div>
              <Button
                // shape="circle"
                className="custom-White-button"
                // disabled
                icon={<IconDownload />}
                onClick={()=>this.setState({downVisible: true})}
              >
              导出文件
              </Button>
              <Button
                // shape="circle"
                className="custom-White-button"
                // disabled
                icon={<IconSync />}
              >
              刷新
              </Button>
            </div>

          </div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <Card
            bordered={false}
            hoverable
            style={{ marginBottom: 20, borderRadius: 20 }}
            className="Table-card"
          >
            <Table
              style={{ marginTop: 10 }}
              virtualized
              noDataElement={
                <Empty imgSrc={Emptydata_img} description={"暂无收支记录"} />
              }
              no_data={no_data}
              border={{ wrapper: false, cell: false }}
              loading={loading}
              columns={columns}
              data={data}
              pagination={pagination.total === 0 ? false : pagination}
              onChange={this.onChangeTable.bind(this)}
              renderPagination={(paginationNode) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  {paginationNode}
                </div>
              )}
              rowKey="id"
            />
          </Card>
        )}
          <Modal
           style={{
            width: 602,
            borderRadius:12
          }}
          className="down-modal"
          title={
            <div style={{ textAlign: 'left' }}>
              导出文件
            </div>
          }
          visible={downVisible}
          onCancel={() => {
            this.setState({downVisible: false});
          }}
          onOk={() => {
            this.setState({downVisible: false});
          }}
        >
          <div>
            <span>文件类型：</span> CVS
          </div>
          <div style={{display: 'flex', alignItems:'center', marginTop: 10}}>
            <div>时间范围：</div>
            <RadioGroup defaultValue='a' >
              <Radio value='a' className='time-radio' onChange={(value)=>{ this.setState({timeRadio:'a'}) }}>按月</Radio>
              <Radio value='b' className='time-radio' onChange={(value)=>{ this.setState({timeRadio:'b'}) }}>自定义</Radio>
            </RadioGroup>
            {timeRadio == 'b' ? (
                     <DatePicker.RangePicker
                     // triggerElement={null}
                     showTime
                     prefix={<IconCalendar />}
                     className="RangePicker-box"
                     style={{ width: 230 }}
                     format='YYYY-MM-DD'
                     value={deriveValue}
                     onSelect={(valueString, value) => {
                       this.setDates2(value);
                     }}
                     onChange={(v) => this.onChange2(v)}
                     onOk={this.onOk.bind(this)}
                     disabledDate={(current) => {
                       if (deriveValue && deriveValue.length && current) {
                         const tooLate =
                         deriveValue[0] &&
                           Math.abs(current.diff(deriveValue[0], "day")) > 28;
                         const tooEarly =
                         deriveValue[1] &&
                           Math.abs(deriveValue[1].diff(current, "day")) > 28;
                         return tooEarly || tooLate;
                       }
                       return false;
                     }}
                   />
            ) : ''}
          </div>
        </Modal>
      </div>
    );
  }
}
export default Paymentdetails;
