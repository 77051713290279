import React from "react";
import { RouteObject } from "react-router-dom";
import Overview from "pages/overview/index";
import Myapp from "pages/myapp/index";
import Myappindex from "pages/myapp/myappindex";
import Addmyapp from "pages/myapp/addmyapp";
import ProductExperience from "pages/myapp/productExperience";
import AigcExperience from "pages/myapp/aigcExperience";
import SaasResume from "pages/myapp/saasResume";
import WordErrorReset from "pages/myapp/wordErrorReset";
import WordClass from "pages/myapp/wordClass";
import GlobalModle from "pages/myapp/globalModle";
import Question from "pages/myapp/question";
import SemanticPrototype from "pages/myapp/semanticprototype";
import EntityExtraction from "pages/myapp/entityExtraction";
import ResumeDocument from "pages/myapp/resumeDocument";
import Embroidery from "pages/myapp/embroidery";
import Writing from "pages/myapp/writing";
import Writingcurrency from "pages/myapp/writingcurrency";
import WordCloud from "pages/myapp/wordCloud";
import Knowledge from "pages/myapp/knowledge";
import Image2Text from "pages/myapp/image2text";
import SmartEditor from "pages/myapp/smarteditor";
import Resources from "pages/myapp/resources";
import AppDataset from "pages/myapp/appdataset";
import CallDetails from "pages/myapp/calldetails";
import Interfacetest from "pages/myapp/interfacetest";
import Index from "pages/index/index";
import NotFound from "pages/notfound/index";
import EngineFactory from "pages/enginefactory";
import Model from "pages/enginefactory/model";
import Modeladd from "pages/enginefactory/model/add";
import Management from "pages/enginefactory/model/management";
import NewModelVersion from "pages/enginefactory/model/management/add";
import VersionDetails from "pages/enginefactory/model/management/versiondetails";
import DataSet from "pages/enginefactory/dataset";
import NewDataset from "pages/enginefactory/dataset/add";
import DatasetDetails from "pages/enginefactory/dataset/details";
import EchnicalSupport from "pages/echnicalsupport";
import EchnicalWorkorder from "pages/echnicalsupport/workorder";
import EchnicalSupportDetails from "pages/echnicalsupport/details/index";
import PersonalCenter from "pages/personalcenter";
import MemberManagement from "pages/personalcenter/membermanagement";
import Payment from "pages/payment";
import Paymentdetails from "pages/payment/details";
import Paymentbreakdown from "pages/payment/breakdown";
import Paymentinformation from "pages/payment/information";
import Paymentgeneral from "pages/payment/general";
import Paymentinorder from "pages/payment/order";
import PaymentAdministration from "pages/payment/administration";
import InvoiceDetail from "pages/payment/invoicedetail";
import PaymentDetail from "pages/payment/paymentdetail";
import InvoiceinFormation from "pages/payment/invoiceinformation";
import Privilege from "pages/privilege";
import Engineservices from "pages/engineservices";
import Engine from "pages/engineservices/engine";
import Billingmethod from "pages/engineservices/billingmethod";
import Promotion from "pages/promotion";
import Accountrecharge from "pages/payment/accountrecharge";
import Agreement from "pages/agreement/index";
import PrivacyAgreement from "pages/agreement/privacyAgreement";
import SmartContract from "pages/myapp/smartContract";
import ShareMyapp from "pages/shareMyapp/index";
import UserAuthentication from "pages/authentication/index";
import PersonalAuthentication from "pages/personalauthentication/index";
import EmotionClass from "pages/myapp/emotionClass";
import PositionDetection from "pages/myapp/positionDetection";
// import PostMatching from "pages/myapp/postMatching";
import SensitiveWords from "pages/myapp/sensitiveWords";
// import MaterialPortrait from "pages/myapp/materialPortrait";
import Similarity from "pages/myapp/similarity";
import KnowledgeReasoning from "pages/myapp/knowledgereasoning";
import EnteringGinseng from "pages/myapp/enteringGinseng";
// import JobPortrait from "pages/myapp/jobPortrait";
import CopydoneArticle from "pages/myapp/copydoneArticle";

const routes: RouteObject[] = [
  {
    path: "/",
    title: "首页", //菜单标题名称
    icon: "TeamOutlined", //图标名称
    element: <Index />,
    children: [
      //子菜单列表
      {
        path: "/",
        title: "概览",
        icon: "icon-icon_gailan_14",
        visual: true,
        disabled: false,
        element: <Overview />,
      },
      {
        path: "/myapp",
        title: "我的应用",
        icon: "icon-icon_yingyong_14",
        visual: true,
        disabled: false,
        showchildren: false,
        element: <Myapp />,
        children: [
          {
            path: "/myapp",
            title: "我的应用",
            visual: false,
            element: <Myappindex />,
          },
          {
            path: "/myapp/addmyapp",
            title: "我的应用",
            visual: false,
            element: <Addmyapp />,
          },
          {
            path: "/myapp/productExperience",
            title: "文段续写",
            visual: false,
            element: <ProductExperience />,
          }, //文段续写
          {
            path: "/myapp/aigcExperience",
            title: "chatppt",
            visual: false,
            element: <AigcExperience />,
          }, 
          {
            path: "/myapp/saasResume",
            title: "chatppt",
            visual: false,
            element: <SaasResume />,
          }, 
          {
            path: "/myapp/question",
            title: "问答生成",
            visual: false,
            element: <Question />,
          }, //问答生成
          {
            path: "/myapp/semanticprototype",
            title: "语义样机",
            visual: false,
            element: <SemanticPrototype />,
          }, //语义样机
          {
            path: "/myapp/entityExtraction",
            title: "实体抽取",
            visual: false,
            element: <EntityExtraction />,
          }, //实体抽取
          {
            path: "/myapp/resumeDocument",
            title: "智能简历",
            visual: false,
            element: <ResumeDocument />,
          }, //实体抽取
          {
            path: "/myapp/embroidery",
            title: "文本润色",
            visual: false,
            element: <Embroidery />,
          }, //文本润色
          {
            path: "/myapp/writing",
            title: "短词造句",
            visual: false,
            element: <Writing />,
          }, //短词造句
          {
            path: "/myapp/writingcurrency", //写作通用类
            title: "短词造句",
            visual: false,
            element: <Writingcurrency />,
          },
          {
            path: "/myapp/wordCloud",
            title: "语义词云",
            visual: false,
            element: <WordCloud />,
          }, //语义词云
          {
            path: "/myapp/image2text",
            title: "以图配文",
            visual: false,
            element: <Image2Text />,
          }, // 以图配文
          {
            path: "/myapp/knowledge",
            title: "知识推理",
            visual: false,
            element: <Knowledge />,
          }, //知识图谱
          {
            path: "/myapp/smarteditor",
            title: "智能编辑器",
            visual: false,
            element: <SmartEditor />,
          }, //智能编辑器
          {
            path: "/myapp/resources",
            title: "应用资源",
            visual: false,
            element: <Resources />,
          }, //应用资源
          {
            path: "/myapp/appdataset",
            title: "应用资源详细",
            visual: false,
            element: <AppDataset />,
          }, //应用资源
          {
            path: "/myapp/calldetails",
            title: "调用明细",
            visual: false,
            element: <CallDetails />,
          }, //调用明细
          {
            path: "/myapp/interfacetest",
            title: "接口调用",
            visual: false,
            element: <Interfacetest />,
          }, //接口测试
          {
            path: "/myapp/wordClass",
            title: "文本分类",
            visual: false,
            element: <WordClass />,
          }, //文本分类
          {
            path: "/myapp/wordErrorReset",
            title: "文本纠错",
            visual: false,
            element: <WordErrorReset />,
          }, //文本纠错
          {
            path: "/myapp/globalModle",
            title: "通用模板",
            visual: false,
            element: <GlobalModle />,
          }, //通用模板
          {
            path: "/myapp/smartContract",
            title: "智能合同",
            visual: false,
            element: <SmartContract />,
          },
          {
            path: "/myapp/emotionClass",
            title: "情感分类",
            visual: false,
            element: <EmotionClass />,
          },
          {
            path: "/myapp/positionDetection",
            title: "岗位探测",
            visual: false,
            element: <PositionDetection />,
          },
          // {
          //   path: "/myapp/postMatching",
          //   title: "岗位匹配",
          //   visual: false,
          //   element: <PostMatching />,
          // },
          {
            path: "/myapp/sensitiveWords",
            title: "敏感词检测",
            visual: false,
            element: <SensitiveWords />,
          },
          // {
          //   path: "/myapp/materialPortrait",
          //   title: "人才画像",
          //   visual: false,
          //   element: <MaterialPortrait />,
          // },
          {
            path: "/myapp/similarity",
            title: "相似度",
            visual: false,
            element: <Similarity />,
          },
          {
            path: "/myapp/knowledgereasoning",
            title: "知识推理",
            visual: false,
            element: <KnowledgeReasoning />,
          },
          {
            path: "/myapp/enteringGinseng",
            title: "页面入参",
            visual: false,
            element: <EnteringGinseng />,
          },
          // {
          //   path: "/myapp/jobPortrait",
          //   title: "岗位画像",
          //   visual: false,
          //   element: <JobPortrait />,
          // },
          {
            path: "/myapp/copydoneArticle",
            title: "营销文案",
            visual: false,
            element: <CopydoneArticle />,
          },
        ],
      },
      // {
      //   path: "/enginefactory",
      //   title: "模型中心",
      //   icon: "icon-icon_weinikeyinqinggongchang",
      //   visual: true,
      //   disabled: false,
      //   element: <EngineFactory />,
      //   children: [
      //     {
      //       path: "/enginefactory/model",
      //       title: "我的模型",
      //       visual: true,
      //       element: <Model />,
      //     },
      //     {
      //       path: "/enginefactory/model/add",
      //       title: "我的模型",
      //       visual: false,
      //       element: <Modeladd />,
      //     },
      //     {
      //       path: "/enginefactory/model/management",
      //       title: "模型管理",
      //       visual: false,
      //       element: <Management />,
      //     },
      //     {
      //       path: "/enginefactory/model/management/add",
      //       title: "模型管理",
      //       visual: false,
      //       element: <NewModelVersion />,
      //     },
      //     {
      //       path: "/enginefactory/model/management/versiondetails",
      //       title: "模型版本详细",
      //       visual: false,
      //       element: <VersionDetails />,
      //     },
      //     {
      //       path: "/enginefactory/dataset",
      //       title: "数据集",
      //       visual: true,
      //       element: <DataSet />,
      //     },
      //     {
      //       path: "/enginefactory/dataset/add",
      //       title: "数据集",
      //       visual: false,
      //       element: <NewDataset />,
      //     },
      //     {
      //       path: "/enginefactory/dataset/details",
      //       title: "数据集详细",
      //       visual: false,
      //       element: <DatasetDetails />,
      //     },
      //   ],
      // },
      {
        path: "/echnicalsupport",
        title: "技术支持",
        visual: false,
        disabled: false,
        element: <EchnicalSupport />,
        children: [
          {
            path: "/echnicalsupport",
            index: true,
            title: "工单列表",
            visual: false,
            disabled: false,
            element: <EchnicalWorkorder />,
          },
          {
            path: "/echnicalsupport/details",
            title: "工单列表",
            visual: false,
            disabled: false,
            element: <EchnicalSupportDetails />,
          },
        ],
      },
      {
        path: "/personalcenter",
        title: "个人中心",
        visual: false,
        disabled: false,
        element: <PersonalCenter />,
        children: [
          {
            path: "/personalcenter/membermanagement",
            index: true,
            title: "成员管理",
            visual: false,
            disabled: false,
            element: <MemberManagement />,
          },
        ],
      },
      {
        path: "/payment",
        title: "资源费用",
        visual: false,
        disabled: false,
        element: <Payment />,
        children: [
          {
            path: "/payment",
            title: "资源费用",
            visual: false,
            disabled: false,
            element: <Paymentinformation />,
          },
          {
            path: "/payment/general",
            title: "账户总览",
            visual: false,
            disabled: false,
            element: <Paymentgeneral />,
          },
          {
            path: "/payment/order",
            title: "订单管理",
            visual: false,
            disabled: false,
            element: <Paymentinorder />,
          },
          {
            path: "/payment/details",
            title: "收支流水",
            visual: false,
            disabled: false,
            element: <Paymentdetails />,
          },
          {
            path: "/payment/breakdown",
            title: "余额明细",
            visual: false,
            disabled: false,
            element: <Paymentbreakdown />,
          },
          {
            path: "/payment/administration",
            title: "发票管理",
            visual: false,
            disabled: false,
            element: <PaymentAdministration />,
          },
          {
            path: "/payment/invoiceinformation",
            title: "发票信息",
            visual: false,
            disabled: false,
            element: <InvoiceinFormation />,
          },
          {
            path: "/payment/invoicedetail",
            title: "查看详情",
            visual: false,
            disabled: false,
            element: <InvoiceDetail />,
          },
          {
            path: "/payment/paymentdetail",
            title: "费用明细",
            visual: false,
            disabled: false,
            element: <PaymentDetail />,
          },
          {
            path: "/payment/accountrecharge",
            title: "账户充值",
            visual: false,
            element: <Accountrecharge />,
          },
        ],
      },
      {
        path: "/engineservices",
        title: "资源包服务",
        visual: false,
        element: <Engineservices />,
        children: [
          {
            path: "/engineservices",
            title: "计费方式",
            visual: false,
            disabled: false,
            element: <Engine />,
          },
          {
            path: "/engineservices/billingmethod",
            title: "计费方式",
            visual: false,
            disabled: false,
            element: <Billingmethod />,
          },
        ],
      },
      {
        path: "/promotion",
        title: "升级资源包",
        visual: false,
        element: <Promotion />,
      },
      {
        path: "/privilege",
        title: "会员特权",
        visual: false,
        element: <Privilege />,
      },
    ],
  },
  {
    path: "/agreement",
    title: "韦尼克智能创作平台相关用户协议",
    visual: false,
    element: <Agreement />,
  },
  {
    path: "/privacyAgreement",
    title: "韦尼克智能创作平台相关隐私政策",
    visual: false,
    element: <PrivacyAgreement />,
  },
  {
    path: "/shareMyapp",
    title: "应用分享",
    visual: false,
    element: <ShareMyapp />,
  },
  {
    path: "/authentication",
    title: "用户认证",
    visual: false,
    element: <UserAuthentication />,
  },
  {
    path: "/personalAuthentication",
    title: "个人信息认证",
    visual: false,
    element: <PersonalAuthentication />,
  },
  {
    path: "*",
    title: "404",
    visual: false,
    element: <NotFound />,
  },
];

export default routes;
