import React, { Component } from "react";
import {
  Space,
  Button,
  Statistic,
  Divider,
  Link,
  Spin,
  Switch,
  Radio,
  List,
  Empty,
  Typography,
  Tooltip,
  Modal,
  Notification,
} from "@arco-design/web-react";
import history from "common/utils/history";
import {
  IconStarFill,
  IconRight,
  IconQuestionCircleFill,
  IconCheck
} from "@arco-design/web-react/icon";
import {
  getenginepackage,
  getuserpackage,
  getbalance,
  open_settlement,
  close_settlement,
  getuserInfo,
} from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import File from "asserts/File.png";
import Image from "asserts/Image.png";
import Text from "asserts/Text.png";
import PPT from "asserts/PPT.png";
import words_img from "asserts/words_img.png";
import image_img from "asserts/image_img.png";
import document_img from "asserts/document_img.png";
import "./index.scss";
// 封装组件
class Paymentinformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      isautomatic: false,
      available_limit:0, //可用余额
      balance: 0, //充值余额
      credit_limit:0, //信用余额
      give_limit:0, //赠送额度
      used_limit:0, //使用金额
      amount_in_arrears:0, //使用金额
      data: [], //通用资源
      specialData: [], //专用资源
      userinfo: {},
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 20,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      scrollLoading: <Spin loading={true} />,
      loading: false,
      no_data: false, //通用资源是否有数据
      special_no_data: false, //专用资源是否有数据
      type_name: "全部资源",
      positionList: [
        "全部资源",
        "智能写作",
        "智能图文",
        "智能文档",
        "知识图谱",
      ],
      maxHeight: this.setmaxHeight(),
      productstyle: JSON.parse(localStorage.getItem("productstyle")),
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      visible: false,
      modaltext: "",
      imgList : {
        "File":File,
        "Image":Image,
        "Text":Text,
        "PPT":PPT,
        "TextFree":'https://saas.file.yoo-ai.com/20230324/f2023032-4120-7296-6164-c4d43cc31f35.png',
      },
      ModalContactService: false,
      countStatusZero:0,
      packageData: [
        {
          banner:words_img,
          name:'字数生成包',
          price:'0.008',
          unit:'千token',
          packageType:'TEXT',
          status:0
        },
        {
          banner:document_img,
          name:'文档生成包',
          price:'0.01',
          unit:'份',
          packageType:'FILE',
          status:0
        },
        {
          banner:image_img,
          name:'图片生成包',
          price:'0.01',
          unit:'张',
          packageType:'IMAGE',
          status:0
        }
      ],
    };
  }
  componentDidMount() {
    // this.getenginepackage();
    this.getbalance();
    //请求用户资源包
    this.getuserpackage();
    this.setInfo()
  }
  UNSAFE_componentDidUpdate() {}
  setmaxHeight() {
    let height = 600;
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    height = winHeight - 615;
    return height + "px";
  }
  getbalance() {
    getbalance().then((res) => {
      if (res.code === 200) {
        this.setState({
          available_limit:res.data.available_limit, //可用余额
          balance:res.data.balance, //充值余额
          credit_limit:res.data.credit_limit, //信用余额
          give_limit:res.data.give_limit, //赠送额度
          used_limit:res.data.used_limit, //使用金额
          amount_in_arrears:res.data.amount_in_arrears, //使用金额
          isautomatic: res.data.settlement_status,
        });
      }
    });
  }
  setInfo(){
    getuserInfo().then((res) => {
      if (res.code === 200) {
        this.setState({
          userinfo: res.data,
        });
      }
    });
  }
  getuserpackage() {
    getuserpackage().then((res) => {
      if (res.code === 200) {
        // console.log(res.data);
        let list = res.data;
        const newPage = this.state.packageData.map(item=>({
          ...item,
          status:res.data.open_package[item.packageType] ? 1 : 0
        }))
        // 统计 status 为 0 的项的数量
        const countStatusZero = newPage.filter(item => item.status === 1).length;
        // console.log(newPage,countStatusZero);
          this.setState({
            data: list["COMMON"],
            specialData: list["EXCLUSIVE"],
            loading: false,
            loadingbody: false,
            no_data: list["COMMON"].length > 0 ? false : true,
            special_no_data: list["EXCLUSIVE"].length > 0 ? false : true,
            scrollLoading: false,
            packageData:newPage,
            // countStatusZero:countStatusZero,
            countStatusZero:0,
          });

          //添加颜色
          if(this.state.data){
            this.state.data.map((item)=>{
              if(item.name == '文件资源包'){
                item['color'] = 'rgb(99, 188, 120)'
                item['bagcolor'] = 'linear-gradient(180deg, #C9F6CA 3%, rgba(255,255,255,0.00) 100%)'
              } else if(item.name == '图片资源包'){
                item['color'] = 'rgb(116, 203, 209)'
                item['bagcolor'] = 'linear-gradient(180deg, #DCF4F6 2%, #FFFFFF 98%)'
              } else if(item.name == '文字资源包'){
                item['color'] = 'rgb(178, 153, 228)'
                item['bagcolor'] = 'linear-gradient(180deg, #F1E9FF 0%, #FFFFFF 100%)'
              }
            })
          }
          this.setState(prevState => ({
            specialData: prevState.specialData.filter(item => item.name !== '初始化')
          }));
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
    // console.log(this.state.specialData,'通用资源');
  }

  getenginepackage() {
    let page = {
      // per_page: this.state.pagination.pageSize,
      // page: this.state.pagination.current,
      type: this.state.type_name === "全部资源" ? "" : this.state.type_name,
      card: true,
    };
    getenginepackage(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        if (list.data.length !== 0) {
          this.setState({
            data: list.data,
            loading: false,
            loadingbody: false,
            no_data: list.data.length > 0 ? false : true,
            // no_data: true,
            scrollLoading: false,
          });
        } else {
          this.setState({
            loading: false,
            loadingbody: false,
            no_data: true,
          });
        }
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  onsetPosition(value) {
    this.setState({
      data: [],
      type_name: value,
      scrollLoading: <Spin loading={true} />,
    });
    setTimeout(() => {
      this.getenginepackage();
    }, 100);
  }
  support(e){
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      ModalContactService: true,
    })
  }
  onCancelContact(value) {
    this.setState({
      ModalContactService: value,
    })
  }
  onClickBtn() {
    history.push("/engineservices");
    history.go();
  }
  onRechargen() {
    // 审核状态 PENDING-待审核 APPROVED-已审核 REJECTED-审核不通过 NOT_APPLIED-未申请
    if(this.state.userinfo.group_permission === "PERSONAL"){
      if(this.state.userinfo.identity === 'APPROVED'){
        history.push("/payment/accountrecharge");
        history.go();
      } else {
        history.push("/personalAuthentication");
        history.go();
      }
    } else {
      history.push("/payment/accountrecharge");
      history.go();
    }
  }
  onPromotion(res) {
    history.push("/myapp/resources");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  openEngine(){
    history.push("/engineservices");
    history.go();
  }
  onUpgrade(res, e) {
    e.stopPropagation();
    history.push("/promotion");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  openModal(val) {
    let title = "";
    if (this.state.balance < 100) {
      title =
        "<h4>余额不足，请充值</h4><span>开通自动月结功能，需有至少100元账户余额，请充值后再开通。</span>";
    } else {
      title = this.state.isautomatic
        ? "<h4>即将关闭月结计费模式</h4><span>自动月结关闭后，资源包消耗完毕，将自动暂停资源服务</span>"
        : "<h4>即将开启月结计费模式</h4><span>开启月结后，资源使用将按实时消耗计费，月底从账户余额扣除总额。</span>";
    }
    this.setState({
      visible: true,
      modaltext: title,
    });
  }
  //金额的千分位转化
  formatAmount = (amount) => {
    // 将金额转换为字符串
    let formattedAmount = String(amount);
  
    // 检查是否存在小数点
    const hasDecimal = formattedAmount.includes('.');
  
    // 如果没有小数点，则添加 .00
    if (!hasDecimal) {
      formattedAmount += '.00';
    }
  
    // 将金额按照千位分隔符分组
    formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // 添加货币符号
    formattedAmount = `${formattedAmount}`;
  
    return formattedAmount;
  };
  onCancel(val) {
    this.setState({
      visible: false,
    });
    if (val === 1) {
      if (this.state.balance < 100) {
        this.onRechargen();
      } else {
        if (this.state.isautomatic) {
          close_settlement().then((res) => {
            if (res.code === 200) {
              this.getbalance();
            } else {
              Notification.warning({ content: res.msg });
            }
          });
        } else {
          open_settlement().then((res) => {
            if (res.code === 200) {
              this.getbalance();
            } else {
              Notification.warning({ content: res.msg });
            }
          });
        }
      }
    }
  }
  render() {
    const {
      loadingbody,
      available_limit,
      balance,
      credit_limit,
      give_limit,
      used_limit, 
      amount_in_arrears,
      isautomatic,
      positionList,
      type_name,
      data,
      specialData,
      scrollLoading,
      maxHeight,
      no_data,
      special_no_data,
      Selectoptions,
      productstyle,
      visible,
      modaltext,
      imgList,
      ModalContactService,
      packageData,
      countStatusZero
    } = this.state;
    return (
      <div className="payment-formation">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">资源费用</div>
            </div>
            <div className="payment-balance columnSpaceStart">
              <div className="payment-label rowStartWrap">
                <svg
                  t="1652086423363"
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2461"
                  width="18"
                  height="18"
                  data-spm-anchor-id="a313x.7781069.0.i5"
                >
                  <path
                    d="M512.512 47.616c-254.464 0-460.8 206.336-460.8 460.8s206.336 460.8 460.8 460.8 460.8-206.336 460.8-460.8-206.336-460.8-460.8-460.8z m144.896 445.952v46.592h-114.176v64.512h114.176v47.616h-114.176v93.696H484.352v-93.696H365.568v-47.616h118.272v-64.512H365.568v-46.592h100.864l-122.88-211.456H409.6c57.856 104.96 92.672 172.032 104.96 200.192h1.024c4.096-11.776 15.872-34.816 34.304-70.144l70.656-130.048h62.464l-124.416 211.968h98.816z"
                    fill="#DEAB4F"
                    p-id="2462"
                    data-spm-anchor-id="a313x.7781069.0.i4"
                    className="selected"
                  ></path>
                </svg>
                <span className="AlibabaPuHuiTi-Medium">可用余额</span>
              </div>
              <div className="payment-end GroupTable-fixe-end">
                <div className="rowFlexStartEnd">
                  <Statistic
                    extra=""
                    style={{ marginBottom: "-12px" }}
                    styleValue={{ fontSize: "44px" }}
                    // value={balance}
                    value={available_limit}
                    groupSeparator
                    precision={2}
                    prefix="¥"
                  />
                  <Button
                    type="primary"
                    className="btn-Recharge"
                    onClick={this.onRechargen.bind(this)}
                  >
                    我要充值
                  </Button>
                  <div className="monthly-settlement">
                    <Tooltip
                      className="monthly-tooltip"
                      color="#FFFFFF"
                      position="bl"
                      trigger="hover"
                      content="自动月结功能默认开启，在没有权益包的情况下，默认使用充值余额进行消费；余额消费采用实时计费消耗显示，月底会根据当月实际的消费总额进行结算，在有阶梯扣费的情况下多退少补。"
                    >
                      <IconQuestionCircleFill />
                    </Tooltip>
                    <span>自动月结</span>
                    <Switch
                      checked={isautomatic}
                      onChange={this.openModal.bind(this)}
                    />
                  </div>
                  <Link href="/engineservices/billingmethod" >查看计费方式</Link>
                </div>
                <div>
                  <Space
                    split={<Divider type="vertical" />}
                    className="Link-btn"
                  >
                    <Link href="/payment/paymentdetail">费用明细</Link>
                    <Link href="/payment/details">收支流水</Link>
                    <Link href="/payment/administration" disabled>发票管理</Link>
                    <Link href="/payment/order">订单管理</Link>
                  </Space>
                </div>
              </div>
              <div className="payment-prcieDetail">
                  <div className="payment-prcieDetail-lable AlibabaPuHuiTi-Medium">
                    <span><span className="total-money-symbol">
                    ¥</span>{this.formatAmount(available_limit)} </span> = <span className="money-symbol">
                    ¥</span>{this.formatAmount(balance)} + <span className="money-symbol">
                    ¥</span>{this.formatAmount(credit_limit)} + <span className="money-symbol">
                    ¥</span>{this.formatAmount(give_limit)} - <span className="money-symbol">
                    ¥</span>{this.formatAmount(used_limit)} - <span className="money-symbol">
                    ¥</span>{this.formatAmount(amount_in_arrears)} </div>
                    <div style={{
                      display: 'flex', alignItems: 'flex-end'
                    }}>
                      <div className="payment-prcieDetail-text AlibabaPuHuiTi-Regular">可用余额 (¥) = 充值金额 + 信用额度 + 赠送金额 - 消费金额 - 欠费金额 </div>
                      <span style={{
                        fontSize: '32px',
                        paddingLeft: '15px',
                        cursor: 'pointer'
                        }}
                        onClick={this.support.bind(this)}
                      >
                          <IconQuestionCircleFill />
                      </span>
                    </div>
             
              </div>
            </div>
            
            {/* <div className="GroupTable-fixe" id="result_title">
              <Radio.Group
                type="button"
                name="direction"
                value={type_name}
                onChange={this.onsetPosition.bind(this)}
                options={positionList}
                className="GroupTable"
              ></Radio.Group>
              <div>
                <Button
                  className="custom-White-button"
                  onClick={this.onClickBtn.bind(this)}
                >
                  全部引擎服务
                </Button>
              </div>
            </div> */}
            

          { special_no_data ? ( <></> ) : (

              <>
                <div className="Header-init">
                  <div className="Header-title">专用资源</div>
                </div>
                <div className="loadingList" >
                    <List
                      bordered={false}
                      style={{ minHeight: "100%" }}
                      scrollLoading={scrollLoading}
                      dataSource={specialData}
                      noDataElement={
                        <Empty
                          imgSrc={Emptydata_img}
                          description={"暂无引擎资源"}
                        />
                      }
                      render={(item, index) => (
                        <List.Item
                          key={index}
                          onClick={this.onPromotion.bind(this, item)}
                        >
                          <div className="engine-resources chappt-resources">
                            <div
                              className="resources"
                              style={{
                                borderColor: 'rgba(64, 110, 255, 1)',
                                "--gradientcolor":
                                  "linear-gradient(180deg, #F0F4FF 50%, rgba(255,255,255,0.00) 100%)",
                              }}
                            >
                              { item.model_capability =='PPT' ? 
                              (<div className="bluemodel">chatPPT</div>) :
                               (<div className="bluemodel">{item.name}</div>)}
                              <div className="resources-box">
                                {/* <div className="resources-title AlibabaPuHuiTi-Medium">
                                  {Selectoptions[item.model_capability].label}
                                </div> */}
                                {item.is_free ? (
                                  <Statistic
                                    extra={
                                      Selectoptions[item.model_capability].label
                                    }
                                    value="限时免费"
                                    groupSeparator
                                  />
                                ) : (
                                  <Statistic
                                    extra={
                                      item.name
                                    }
                                    value={item.balance}
                                    groupSeparator
                                    precision={0}
                                    suffix={item.unit}
                                  />
                                )}

                                <div className="resources-type GroupTable-fixe-end">
                                  <span
                                    className="resources-group"
                                    // style={{
                                    //   "--color": productstyle[item.group].textcolor,
                                    // }}
                                  >
                                    {/* #{item.group} */}
                                  </span>
                                  <div className="resources-icon">
                                    <img
                                      src={
                                        imgList[item.model_capability] ? imgList[item.model_capability] : imgList['TextFree']
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="upgrade">
                                <span
                                  onClick={this.onUpgrade.bind(this, item)}
                                  // style={{
                                  //   "--color": productstyle[item.group].textcolor,
                                  // }}
                                >
                                  升级资源包
                                  <IconRight />
                                </span>
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                </div>
              </>
            )}


            {/* 通用资源 */}
            
              <>
                  <div className="Header-init">
                    <div className="Header-title">通用资源</div>
                  </div>
                  <div className="loadingList commonList" >
                    {countStatusZero === 0 ? (
                      
                      <div className="commonList-empty">
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无引擎资源"}
                            />
                            {/* <Button
                              type="primary"
                              className="btn-Recharge"
                              onClick={this.openEngine.bind(this)}
                            >
                              开通引擎服务
                            </Button> */}
                               <Button
                              type="dashed"
                              style={{
                                width:"117px",
                                height: "42px",
                                borderRadius: "8px",
                                margin:"0 auto",
                              }}
                            >
                              待开放
                            </Button>
                      </div>
                    ) : (
                          <List
                          bordered={false}
                          style={{ minHeight: "100%" }}
                          scrollLoading={scrollLoading}
                          dataSource={packageData}
                          // grid={{
                          //   sm: 24,
                          //   md: 12,
                          //   lg: 8,
                          //   xl: 6,
                          // }}
                          noDataElement={
                            <>
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无引擎资源"}
                            />
                            <Button
                              type="primary"
                              className="btn-Recharge"
                              onClick={this.openEngine.bind(this)}
                            >
                              开通引擎服务
                            </Button>
                            </>
                          }
                          render={(item, index) => (
                            <List.Item
                              key={index}
                              onClick={this.onPromotion.bind(this, item)}
                              style={{display:item.status == 1 ?'block':'none'}}
                            >
                              <div className={`services-package-box ${item.packageType}-box`} key={index} >
                                  <div className="tag-mode-container" style={{display:item.status == 1 ?'block':'none'}}>
                                    <div className="tag">
                                      <span><IconCheck style={{fontSize:'30px'}}/></span>
                                    </div>
                                  </div>
                                  <div className="services-package-item">
                                    <img
                                      src={item.banner}
                                      className="model_img"
                                      alt=""
                                    />
                                    <div className="services-package-ul">
                                      <div className="services-package-name">{item.name}</div>
                                      <div className="services-package-desicrt">最低{item.price}元/{item.unit}</div>
                                    </div>
                                  </div>
                                  <Button 
                                  className={`services-package-btn ${item.status === 1 ? 'services-package-btn-play' : ''}`}>
                                    {item.status === 1 ? '查看详情' : '开通服务'}
                                  </Button>
                              </div>
                            </List.Item>
                          )}
                        />
                    )
                    }
                
                  </div>
              </>
               


            

            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                <IconStarFill />
                使用说明
              </div>
              <div>
                1.计费方式有三种，专有权益包，权益包，预充值，扣除优先级为 专有权益包 &gt; 权益包 &gt; 充值。
                <br />
                2.预充值以接口请求成功次数作为核算口径进行计费，不同的产品，单次请求的价格不同，根据实际使用的接口情况进行结算，月底根据当月实际使用总量，按实际价格档位进行月结，多退少补。
                <br />
                3.增加字数权益包，针对文本生成类的产品，可以基于字数去扣除；针对文档类和图片类的产品，可以基于份数去扣除。当有权益包时，默认走权益包的结算方式。当权益包用尽时，默认走预充值的结算方式，当预充值中没有余额时，服务暂停。
                <br />
                4.针对特定产品的定制的权益包，由管理人员从后台配置，当配置了专有权益包时，默认走权益包的结算方式。当权益包用尽时，默认走预充值的结算方式，当预充值中没有余额时，服务暂停。
                <br />
                5.自动月结需用户手动开通后生效
              </div>
            </div>
          </>
        )}
        <Modal
          title="操作提示"
          visible={visible}
          onCancel={() => this.onCancel(0)}
          onOk={() => {
            this.onCancel(1);
          }}
          okText={this.state.balance < 100 ? "充值" : "确定"}
          cancelText="取消"
          className="custom-modal renew-modal"
        >
          <div className="custom-box">
            <div dangerouslySetInnerHTML={{ __html: modaltext }}></div>
          </div>
        </Modal>
        <Modal
          visible={ModalContactService}
          title={<div style={{ textAlign: 'left' }}>余额说明</div>}
          footer={null}
          className="Contactmodal payment-modal"
          maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
          onCancel={() => {
            this.onCancelContact(false)
          }}
        >
            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                <IconStarFill />
                详细说明
              </div>
              <div className="payment-modal-detail">
                <ul>
                <li>
                  可用余额：为用户当前可用于购买资源包和用量扣费的额度<br />
                </li>
                <li>
                  充值金额：为用户实际充值的金额。当充值金额用完后，发短信提示用户充值，并启用信用额度。使用信用额度期间，每天都发短信提示用户充值<br />
                  </li>
                 <li>
                  信用金额：默认根据用户充值的金额，予以一定的信用额度，作为用户充值金额使用完后，在用户再次充值前，确保用户服务不被停止的缓冲额度。当信用额度被用完以后，用户的服务被停止。当费用交清后，再重新启用。<br />
                  </li>
                  <li>
                  赠送金额：通过包含但不仅限于兑换码、优惠券，后台赠送等方式给用户赠送的额度<br />
                  </li>
                  <li>
                  使用金额：为用户在充值额度允许的范围内，正常扣费使用的金额<br />
                  </li>
                  <li>
                  欠费金额：为用户充值金额扣完后，在信用额度使用期间，实际使用待充值缴清的金额<br />
                  </li>
                </ul>
              </div>
            </div>
        </Modal>
      </div>
    );
  }
}
export default Paymentinformation;
