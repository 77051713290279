import { localAxios } from "api/request";

//切换用户所属企业
export let switchIdentities = localAxios("post", "/v2/auth/switch-identities");
//注销授权/退出登录
export let outAuth = localAxios("delete", "/v2/auth/token");

export let birthEight = localAxios("get", "/v2/auth/wechat");
export let getuserInfo = localAxios("get", "/v2/user/info");
export let putuserInfo = localAxios("put", "/v2/user/info");
export let putusermobile = localAxios("put", "/v2/user/mobile");
export let checkWechatBind = localAxios("post", "/v2/user/check-wechat-bind");

// 解除绑定微信
export let deleteWechatBind = localAxios("delete", "/v2/user/wechat");
//账户余额
export let getbalance = localAxios("get", "/v2/user/balance");
//上传文件
export let fileUp = localAxios("post", "/v2/file/up");

// 工单处理（技术支持）
//获取工单列表
export let getIssuesList = localAxios("get", "/v2/issues");
//添加工单
export let addIssues = localAxios("post", "/v2/issue");
//获取工单信息
export let getIssueId = localAxios("get", "/v2/issue");
//获取工单回复列表
export let getIssueReplies = localAxios("get", "/v2/issue/replies");
//追加工单回复
export let postIssueReplie = localAxios("post", "/v2/issue/reply");
//删除工单
export let deleteIssue = localAxios("delete", "/v2/issue");
//更新工单
export let putIssue = localAxios("put", "/v2/issue");
// 智能编辑器
//获取
export let geteditor = localAxios("get", "/v2/m-resume-editor");
//提交
export let posteditor = localAxios("post", "/v2/m-resume-editor");
//更新
export let puteditor = localAxios("put", "/v2/m-resume-editor");
// 基础信息
export let getconfig = localAxios("get", "/v2/config");

// 版本选择
export let putmodelversion = localAxios("put", "/v2/wccg/model-version-select");
export let postmodelVersionTrain = localAxios(
  "post",
  "/v2/wccg/model-version-train"
);

// 接口套餐
export let getpackage = localAxios("get", "/v2/api/package");
export let getstatistics = localAxios("get", "/v2/api/statistics");
// 我的模型
export let getmodelList = localAxios("get", "/v2/wccg/model-list");
export let postmodelversion = localAxios("post", "/v2/wccg/model-version");
export let deleteModelVersion = localAxios("delete", "/v2/wccg/model-version");
export let postmodel = localAxios("post", "/v2/wccg/model");
export let deletemodel = localAxios("delete", "/v2/wccg/model");
export let getmodelListVersion = localAxios(
  "get",
  "/v2/wccg/model-version-list"
);
export let getmodelTrainProcess = localAxios(
  "get",
  "/v2/wccg/model-train-process"
);
export let getaddlist = localAxios("get", "/v2/wccg/app-add-list");
// 模型版本详细
export let getmodelVersionDetail = localAxios(
  "get",
  "/v2/wccg/model-version-detail"
);
// 数据集
export let getdatumList = localAxios("get", "/v2/wccg/datum-list");
export let postdatum = localAxios("post", "/v2/wccg/datum");
export let getdatumdetail = localAxios("get", "/v2/wccg/datum-detail");
export let deletedatum = localAxios("delete", "/v2/wccg/datum");
export let deletedatumdetail = localAxios("delete", "/v2/wccg/datum-detail");

// 应用列表
export let getappList = localAxios("get", "/v2/wccg/app-list");
export let postapp = localAxios("post", "/v2/wccg/app");
export let deleteapp = localAxios("delete", "/v2/wccg/app");
export let newDeleteapp = localAxios("delete", "/v3/apps/delete");
export let putapp = localAxios("put", "/v2/wccg/app");

//新的应用列表
export let newCreateappList = localAxios("post", "/v3/apps/create");
export let getcreateappList = localAxios("get", "/v3/apps/list");
export let newGetappList = localAxios("get", "/v3/apps/info");
export let newRefreshToken = localAxios("post", "/v3/apps/refresh-token");
export let newEditappList = localAxios("put", "/v3/apps/edit");
export let getAllapiList = localAxios("get", "/v3/apps/api-list");
export let apiBind = localAxios("post", "/v3/apps/api-bind");
export let getHostApi = localAxios("get", "/v3/apps/hot-api");
export let getApiClass = localAxios("get", "/v3/apps/api-class");


// 官方体验应用列表
export let getofficialappList = localAxios("get", "/v2/wccg/app-official-list");

// 看板数据统计
export let getboard = localAxios("get", "/v2/wccg/board");
// 接口服务
export let getApiInfo = localAxios("get", "/v2/api/info");
export let getRequest = localAxios("post", "/v2/api/request");
export let knowledgeJd = localAxios("get", "/v2/api/kg-jd");
export let knowledgeFreq = localAxios("post", "/v2/api/kg-frequency");

// 查看是否开启B端平台
export let getopen = localAxios("get", "/v2/wccg/open");
// 开启B端平台服务
export let putopen = localAxios("put", "/v2/wccg/open");
//检测创建权限
export let postcheck = localAxios("post", "/v2/wccg/check");
// 检测创建名称重复
export let postcheckName = localAxios("post", "/v2/wccg/check-name");
// 成员管理
export let getuser = localAxios("get", "/v2/user/get-user-info");
export let getmyenterprise = localAxios("get", "/v2/enterprise/my-enterprise");
export let getgroupinfo = localAxios("get", "/v2/enterprise/group-info");
export let postcalljoin = localAxios("post", "/v2/enterprise/join");
export let postuseroperation = localAxios(
  "post",
  "/v2/enterprise/user-operation"
);
export let deleteuser = localAxios("post", "/v2/enterprise/del-user");
export let getgrouppowerinfo = localAxios(
  "get",
  "/v2/enterprise/group-power-info"
);
export let getagreement = localAxios("get", "/v2/config/agreement");
// 资源及费用
export let getenginepackage = localAxios("get", "/v2/api/engine-package");
export let getuserpackage = localAxios("get", "/v2/api/user-package");
export let allpackage = localAxios("get", "/v2/api/all-package");
export let postopenpackage = localAxios("post", "/v2/api/open-package");
export let packageproduct = localAxios("get", "/v2/order/package-product");
export let saascreate = localAxios("post", "/v2/order/saas-create");
// 充值
export let posttop_up = localAxios("post", "/v2/order/saas-top_up");
export let saas_cancel = localAxios("post", "/v2/order/saas-cancel");

export let order_detail = localAxios("get", "/order/detail");
// 订单管理
export let order_list = localAxios("get", "/v2/order/saas-list");
export let saas_detail = localAxios("get", "/v2/order/saas-detail");
//删除订单
export let delete_order = localAxios("delete", "/v2/order/saas-delete");

// 资源调用明细
export let call_statistics = localAxios("get", "/v2/api/call-statistics");
// 开通月结服务
export let open_settlement = localAxios("post", "/v2/user/open-settlement");
export let close_settlement = localAxios("post", "/v2/user/close-settlement");
// 发票管理
export let invoice_list = localAxios("get", "/v2/invoice/list");
export let invoice_info = localAxios("get", "/v2/invoice/info");
export let add_invoiceinfo = localAxios("post", "/v2/invoice/add-info");
export let edit_invoiceinfo = localAxios("put", "/v2/invoice/edit-info");
export let invoice_apply = localAxios("post", "/v2/invoice/apply");
export let invoice_cancel = localAxios("post", "/v2/invoice/cancel");
export let delete_invoice = localAxios("delete", "/v2/invoice/delete");
export let editReceiveinfo = localAxios("put", "/v2/invoice/edit-receive-info");
//发票详情
export let invoice_detail = localAxios("get", "/v2/invoice/detail");

// 查询资源包、余额状态
export let check_resource = localAxios("get", "/v2/user/check-resource");

//接口调试
//获取接口参数
export let getParams = localAxios("get", "/v2/api/params");
//调试请求应用服务
export let postDebug = localAxios("post", "/v2/api/debug");

// 创建应用分享链接
export let shareLink = localAxios("post", "/v2/share/model-create");

// 获取分享信息
export let getShare = localAxios("get", "/share/model-info");

//调用分享模型
export let shareAppApi = localAxios("post", "/share/model-process");
export let shareKnowledgeJd = localAxios("get", "/share/kg-jd");
export let shareKnowledgeFreq = localAxios("post", "/share/kg-frequency");
// 企业申请
export let enterpriseAdd = localAxios("post", "/v2/enterprise/add");
export let checkaudit = localAxios("get", "/v2/enterprise/check-audit");
// 获取品牌
export let getmarketing = localAxios("post", "/v2/marketing");
// 获取授权code
export let getAuthorizationCode = localAxios("post", "/aigc/code");
// 获取授权token
export let getAuthorizationToken = localAxios("post", "/aigc/token");

// 获取授权token
export let userAudit = localAxios("post", "/v2/user/audit");

// 获取接口调用记录
export let getApilog = localAxios("get", "/v2/user/api-log");

//更新aigc配置
export let setAigcSetting = localAxios("put", "/aigc/setting");

//获取aigc配置
export let getAigcSetting = localAxios("get", "/aigc/setting");

//设置预警余额
export let setBalance = localAxios("post", "/v2/user/balance-alert");

//获取api调用详情
export let getApiDetail = localAxios("get", "/v2/user/api-log-detail");