import React, { Component } from "react";
import { Table, Empty, Card, Spin } from "@arco-design/web-react";
import { getCurrentDate } from "common/utils";
import Emptydata_img from "asserts/Emptydata_img.svg";
import { getbalance } from "api/apis";
import "./index.scss";
// 封装组件
class Paymentdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      keyword: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      columns: [
        {
          title: "交易渠道",
          dataIndex: "use_type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },

        {
          title: "交易类型",
          dataIndex: "transaction_type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record) => (
            <span>
              {col === "CONSUME"
                ? "消费"
                : col === "REFUND"
                ? "提现"
                : col === "TOP_UP"
                ? "充值"
                : ""}
            </span>
          ),
        },
        {
          title: "交易说明",
          dataIndex: "bak",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "流水类型",
          dataIndex: "type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record) => (
            <span>{col === "IN" ? "进账" : col === "OUT" ? "出账" : ""}</span>
          ),
        },
        {
          title: "金额",
          dataIndex: "amount",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "交易时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getbalance();
  }
  UNSAFE_componentDidUpdate() {}
  getbalance() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      type: "list",
    };
    getbalance(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.all_count;
        this.setState({
          pagination: pagination,
          data: this.state.data.concat(...list.data),
          loading: false,
          loadingbody: false,
          no_data: list.all_count > 0 ? false : true,
        });
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getbalance();
  }
  render() {
    const { loadingbody, loading, columns, data, pagination, no_data } =
      this.state;
    return (
      <div className="payment-details">
        <div className="Header-init">
          <div className="Header-title">收支流水</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <Card
            bordered={false}
            hoverable
            style={{ marginBottom: 20, borderRadius: 20 }}
            className="Table-card"
          >
            <Table
              style={{ marginTop: 10 }}
              virtualized
              noDataElement={
                <Empty imgSrc={Emptydata_img} description={"暂无收支记录"} />
              }
              no_data={no_data}
              border={{ wrapper: false, cell: false }}
              loading={loading}
              columns={columns}
              data={data}
              pagination={pagination.total === 0 ? false : pagination}
              onChange={this.onChangeTable.bind(this)}
              renderPagination={(paginationNode) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  {paginationNode}
                </div>
              )}
              rowKey="id"
            />
          </Card>
        )}
      </div>
    );
  }
}
export default Paymentdetails;
