import React, { Component } from "react";
// import { Link } from 'react-router-dom'

import ModalSelect from "components/ModalSelect";
import {
  Notification,
  List,
  Modal,
  Spin,
  Typography,
  Button,
  Divider,
  Space,
  Tag,
  Link,
  Checkbox,
  Empty,
  Carousel,
  Progress,
  Avatar,
  DatePicker,
  Radio,
  Select,
  Message,
} from "@arco-design/web-react";
import {
  IconRight,
  IconLeft,
  IconLoading,
  IconCalendar,
  IconCheckCircleFill,
  IconExclamationCircleFill,
  IconMinusCircleFill,
} from "@arco-design/web-react/icon";
import {
  getmodelListVersion,
  getappList,
  allpackage,
  getboard,
  getconfig,
  getuserInfo,
  getmodelList,
  putapp,
  deleteapp,
  getofficialappList,
  getbalance,
  getAllapiList,
  getcreateappList,
  newGetappList,
  getHostApi,
  call_statistics,
  getuserpackage,
  getApiClass,
} from "api/apis";
import { getCurrentDate, Imageinit, getTimeState, formatMoney } from "common/utils";
import BarEcharts from "components/BarEcharts/index";
import LineEcharts from "components/LineEcharts/index";
import OffModel from "components/OffModel/index";
import ModelPopu from "components/ModelPopu/index";
import SvgLine from "components/SvgLine/index";
import history from "common/utils/history";
import icon1 from "asserts/Intelligentwriting_icon.svg";
import icon2 from "asserts/Intelligentgraphic_icon.svg";
import icon3 from "asserts/Smartdocument_icon.svg";
import icon4 from "asserts/Intelligentcreation_icon.svg";
import icon5 from "asserts/knowledge_graph.svg";
import Emptydata_img from "asserts/Emptydata_img.svg";
import model_img from "asserts/model_img.png";
import ai_ppt from "asserts/ai_ppt.png";
import ai_resume from "asserts/ai_resume.png";
import ai_more from "asserts/ai_more.png";
import ai_marketing from "asserts/ai_marketing.png";
import ai_pact from "asserts/ai_pact.png";
import no_pageimg from "asserts/no_page.png";
import bartable_top from "asserts/bartable_top.png";
import home_vip_icon from "asserts/home_vip_icon.svg";
import home_vip_icon2 from "asserts/home_vip2_icon.svg";
import home_blue_icon from "asserts/home_blue_icon.svg";
import home_blue2_icon from "asserts/home_blue2_icon.svg";
import home_r_icon from "asserts/home_r_icon.svg";
import home_r_icon2 from "asserts/home_r_icon2.svg";
import home_r_icon3 from "asserts/home_r_icon3.svg";
import ai_ppt_api from "asserts/ai_ppt_api.png";
import ai_ppt_saas from "asserts/ai_ppt_saas.png";
import ai_pact_icon from "asserts/ai_pact_icon.png";
import ai_resume_icon from "asserts/ai_resume_icon.png";
import ai_marketing_icon from "asserts/ai_marketing_icon.png";

import "./index.scss";
import JQ from "jquery";
// import { url } from "inspector";

const { Title } = Typography;

// 封装组件

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      identity: JSON.parse(localStorage.getItem("userinfo"))
        ? JSON.parse(localStorage.getItem("userinfo")).group_permission
        : "",
      userinfodata: JSON.parse(localStorage.getItem("userinfo"))
        ? JSON.parse(localStorage.getItem("userinfo"))
        : null,
      has_enterprise: JSON.parse(localStorage.getItem("userinfo")) 
      ? JSON.parse(localStorage.getItem('userinfo')).has_enterprise : 1,
      visibleUpgrade: false,
      modelProgress: [],
      data: [],
      data2: [],
      columns: [
        {
          title: "资源类型",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "API可调用资源数",
          dataIndex: "total",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "剩余可用资源数",
          dataIndex: "balance",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "已调用资源数",
          dataIndex: "used",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "到期时间",
          dataIndex: "expired_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      columns2: [
        {
          title: "模型名称",
          dataIndex: "model_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "模型ID",
          dataIndex: "model_id",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "版本号",
          dataIndex: "version",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "服务选项",
          dataIndex: "model_capability_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "创建时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>{getCurrentDate("-", ":", col)}</span>
          ),
        },
        {
          title: "状态",
          dataIndex: "train_status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={
                    col === "训练成功"
                      ? "green"
                      : col === "训练中"
                      ? "arcoblue"
                      : col === "训练失败"
                      ? "gray"
                      : "orangered"
                  }
                  icon={
                    col === "训练成功" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : col === "训练中" ? (
                      <IconLoading style={{ color: "#165dff" }} />
                    ) : col === "训练失败" ? (
                      <IconMinusCircleFill style={{ color: "#86909C" }} />
                    ) : (
                      <IconExclamationCircleFill style={{ color: "#ff7d00" }} />
                    )
                  }
                >
                  {col}
                </Tag>
              }
            </span>
          ),
        },
      ],
      listoff: [],
      TablLoading: false,
      TabscrollLoading: false,
      activeTab: "0",
      sceneKey: "",
      group_name: "",
      officialScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      officialListTabl: [],
      officialList: [],
      officialpagination: {
        total: 0,
        pageSize: 9,
        current: 1,
      },
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 9,
        current: 1,
        pageSizeChangeResetCurrent: false,
        showJumper: false,
        sort: "train_at,desc",
      },
      modelNewScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      packagePagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 9,
        current: 1,
        pageSizeChangeResetCurrent: false,
        showJumper: false,
      },
      myappList: [],
      myapppagination: {
        total: 0,
        pageSize: 5,
        current: 1,
      },
      loading: false,
      packageLoading: false,
      no_data: false,
      no_package: false,
      isapp: 0,
      dataBarEcharts: {
        x: ["以图配文", "智能文书", "合同编辑", "文段生成", "智能文书"],
        y: [1880, 755, 866, 800, "1000"],
      },
      model_capability: "",
      swiperloading: false,
      scrollLoading: "",
      board: {
        my: { join_day: 0, app_count: 0, api_count: 0, app_max: 0 },
        all: {
          company_count: 0,
          app_count: 0,
          data_count: 0,
          model_success: 0,
        },
      },
      Selectoptions: localStorage.getItem("Selectoptions")
        ? JSON.parse(localStorage.getItem("Selectoptions"))
        : [],
      hoverindex: "",
      scrolltype: "right",
      scrolltype1: 0,
      scrollWidth: 0,
      scrollLeft: 0,
      modelScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      myModelList: [],
      myomdelList: [],
      footpagination: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
      enginelist: [],
      visible: false,
      selectedRowKeys: [],
      visible2: false,
      imageSrc: [],
      data:[],
      styleCss: JSON.parse(localStorage.getItem("productstyle")),
      available_limit: 0,
      credit_limit:0,
      ModeVisible: false,
      createVisible:false,
      modelTimr: null,
      value: "今日",
      rangeValue: ["", ""],
      postrangeValue: ["", ""],
      Tablist: ["今日", "昨日", "近7日", "近30日"],
      ishowdata: false,
      callData: {
        x: ['2024-7-20','2024-7-21','2024-7-22','2024-7-23','2024-7-24'],
        // y: [],
        y1: [12,68,50,37,86],
        // y2: [5,11,8,20,7],
        y1Name:"全部调用总量",
        // y2Name:"单个调用总量",
      },
      no_callData:false,
      selectApply:-1,
      newappList:[],
      newappApi:[],
      no_page:no_pageimg,
      bartable_top:bartable_top,
      createTypeList:[
        {
          type:"ai_ppt",
          name:"智能PPT",
          label:"智能PPT",
          model_desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE'
        },
        {
          type:"ai_resume",
          name:"智能简历",
          label:"智能简历",
          model_desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
          backgroundColor:'#D3EEFF'
        },
        {
          type:"saas_resume",
          name:"智能简历(SaaS版)",
          label:"智能简历(SaaS版)",
          model_desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
          backgroundColor:'#D3EEFF'
        },
        {
          type:"saas_ppt",
          createType:1,
          label:"智能PPT(SaaS版)",
          title:'介绍介绍',
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE',
          isDiable:false,
        },
        {
          type:"ai_marketing",
          name:"智能营销",
          label:"智能营销",
          model_desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_marketing,
          backgroundColor:'#FAEDFF'
        },
        {
          type:"ai_pact",
          name:"智能合同",
          label:"智能合同",
          model_desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_pact,
          backgroundColor:'#ECE8FF'
        }
      ],
      interfaceOption:[
        // {value: 0, label: '智能PPT'},
        // {value: 1, label: '智能简历'},
        // {value: 2, label: '智能营销'},
        // {value: 3, label: '智能合同'},
      ],
      packageData:[
        {
          name:'字数生成包',
          desc:"生成字数（字）",
          balance:0,
          packageType:'TEXT',
          status:0,
          icon:"resource1_icon",
        },
        {
          name:'文档生成包',
          desc:"生成文档（份）",
          balance:0,
          packageType:'FILE',
          status:0,
          icon:"resource3_icon",
        },
        {
          name:'图片生成包',
          desc:"生成图片（张）",
          balance:0,
          packageType:'IMAGE',
          status:0,
          icon:"resource2_icon",
        }
      ],
      no_open:false,
      postValue:'all',
      hostList:[],
      all_total:{},
      applistLoading:false,
    };
    this.statusLineRef = React.createRef();
    this.modelNewRef = React.createRef();
    this.modelLineRef = React.createRef();
    this.officialRef = React.createRef();
    this.initRef = React.createRef();
  }
  componentDidMount() {
    if (!localStorage.getItem("userinfo")) {
      this.setinfo();
    } else {
      this.gitconfig();
      // this.getlist();
      this.getpackage();
      // this.getmodelListVersion();
      // clearInterval(this.state.modelTimr);
      // this.state.modelTimr = setInterval(() => {
      //   this.getmodelListVersion();
      // }, 6000);
      this.getboard();
      this.getHostApi();
      // this.getmodelList();
      // this.getofficialappList(true);
      this.getappList();
      this.getcreateappList();
      this.getbalance();
      this.getpackages();
      this.getuserpackage();
      this.getApiClass();
    }
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns2 = this.state.columns2;
        let json = {
          title: "创建者",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span
              style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
            >
              {col}
            </span>
          ),
        };
        this.setState({
          columns2: columns2.concat(json),
          // imageSrc: [require("asserts/vip_home_img.png")],
          imageSrc: [require("asserts/user_home_img.png")],
        });
      } else {
        this.setState({
          imageSrc: [require("asserts/user_home_img.png")],
        });
      }
    }
    document.addEventListener("click", this.hideAllMenu);
  }
  componentWillUnmount() {
    clearInterval(this.state.modelTimr);
    document.removeEventListener("click", this.hideAllMenu);
  }
  getappList = ()=>{
    // getAllapiList().then(res=>{
    //   if(res.code == 200){
    //     let list = res.data
    //     const listPage = {
    //       '智能PPT':[],
    //       "智能简历":[],
    //       "更多智能文档":[]
    //     }
    //    for(let key in list){
    //       if(key === "智能PPT" || key === "智能简历"){
    //         listPage[key] = list[key]
    //       } else {
    //         listPage["更多智能文档"].push(...list[key])
    //       }
    //    }
    //     this.setState({
    //       newappApi:listPage,
    //     })
    //   }
    // })
    getcreateappList().then(res=>{
      if(res.code == 200){
        const list = res.data.data;
        const newList = [
          {value: 'all', label: '全部应用'},
        ];
        if(list.length > 0){
          list.map((i, index) => {
            newList.push({
              value:i.show_id,
              label:i.name
            })
          })
        } 
        this.setState({
          interfaceOption:newList,
          loadingbody: false,
        })

        setTimeout(() => {
        let officialScroll = this.state.officialScroll;
          if(list.length > 0){
            let Width = this.officialRef.current?.scrollWidth;
            let clientWidth = this.officialRef.current?.clientWidth;
            // if (loading) {
              // this.officialRef.current.scrollLeft += clientWidth;
            // }
            let scrollLeft = this.officialRef.current?.scrollLeft;
            let scrolltype =
              this.officialRef.current?.clientWidth ;
            officialScroll.scrolltype1 = scrolltype;
            officialScroll.scrollWidth = Width;
            officialScroll.scrollLeft = scrollLeft;
            officialScroll.swiperloading = false;
          } else {
            officialScroll.scrollLoading = "没有更多";
            officialScroll.swiperloading = false;
        }
          this.setState({
            officialScroll: officialScroll,
          });
        }, 900);

      } else {
        this.setState({
          interfaceOption:[],
          loadingbody: false,
        })
        Message.error(res.msg)
      }
      
    })
  }
  getuserpackage = ()=>{
    getuserpackage().then(res=>{
      if(res.code == 200){
        const newPage = this.state.packageData.map(item=>({
          ...item,
          status:res.data.open_package[item.packageType] ? 1 : 0
        }))
          this.setState({
            all_total:res.data.all,
          })
        
      const openPackageValues = Object.values(res.data.open_package);
        const allFalse = openPackageValues.every(item => item === false);
        this.setState({
          packageData:newPage,
          no_open: allFalse 
        })
      }
    })
  }
  getcreateappList = ()=>{
    this.setState({
      applistLoading:true,
    })
    getcreateappList().then(res=>{
      if(res.code === 200) {
        
        this.setState({
          newappList:res.data.data,
          applistLoading:false,
          loadingbody: false,
        })
        this.state.newappList.forEach(item=>{
          item.color = this.randomColor()
        })
        
      } else {
        
        this.setState({
          applistLoading:false,
        })

      }
    })
  }
  getHostApi = ()=>{
    getHostApi().then(res=>{
      if(res.code == 200){
        if(res.data && res.data.length > 0){
          let dataBarEcharts = {
            x: [],
            y: [],
          };
          res.data.slice(0,5).map((i, index) => {
            dataBarEcharts.x.push(i.api_name);
            dataBarEcharts.y.push(i.total);
          });
  
          this.setState({
            hostList:res.data,
            dataBarEcharts: dataBarEcharts,
          })
        } else {
          this.setState({
            hostList:[],
          })
        }
      }
    })
  }
  hideAllMenu = () => {
    if (this.state.ishowdata) {
      this.setState({
        ishowdata: !this.state.ishowdata,
      });
    }
  };
  getApiClass(){
    const listPage = {
      "智能PPT":[],
      "智能简历":[],
      "更多智能文档":[],
    }
    const apiKey = ["智能PPT",'智能简历',"智能营销","智能合同"];
    apiKey.forEach(key=>{
      
      getApiClass({
        "class":key,
      }).then(res=>{
        if(res.code === 200){
          
          if(key == "智能PPT" || key == "智能简历"){
            res.data[0].children.forEach((item)=>{
              let params = {
                // class: key == "智能PPT" ? "智能PPT(API)版" : key,
                class: key,
                desc: "",
                name: item.name,
                id:item.id,
                icon:"",
              }
              if(!item.children){
                listPage[key].push(params)
              }
              if(item.children){
                item.children.forEach((ite)=>{
                  let params2 = {
                    class: key,
                    desc: "",
                    name: ite.name,
                    id:ite.id,
                    icon:"",
                  }
                  listPage[key].push(params2)
                })
              }
              
            })
          } else {
            res.data[0].children.forEach((item)=>{
              let params = {
                class: res.data[0].name,
                desc: "",
                name: item.name,
                id:item.id,
                icon:"",
              }
              if(!item.children){
                listPage["更多智能文档"].push(params)
              }
              if(item.children){
                item.children.forEach((ite)=>{
                  let params2 = {
                    class: res.data[0].name,
                    desc: "",
                    name: ite.name,
                    id:ite.id,
                    icon:"",
                  }
                  listPage["更多智能文档"].push(params2)
                })
              }
              
            })
          }
          
        }
      })

    })

    listPage["智能PPT"].unshift({
      "class": "智能PPT(SaaS版)",
      "desc": "在线体验chatppt",
      "name": "chatppt",
      "id": 3,
      "icon": ""
  })
    this.setState({
      newappApi: listPage
    })
    
  }

  
  setvisibleUpgrade() {
    history.push("/privilege");
    history.go();
  }
  //检查智能简历应用中是否有选中体验页面的api功能
  checkResum(apilist){
    const key = ["AI简历生成","简历润色","简历续写","简历扩写","短词成文","简历精炼"];
    return apilist.every(item => !key.some(k => item.name.includes(k)));
  }
  goOffdetails(record) {
    // console.log(record,'777');
    let model = {
      '智能PPT(API版)':'ai_ppt',
      '智能简历(SaaS版)':'saas_resume',
      'SAAS简历':'saas_resume',
      '智能简历':'ai_resume',
      '智能营销':'ai_marketing',
      '智能合同':'ai_pact',
    }
    // return
    const saasresume = {
      'SAAS简历探测':'detect',
      'SAAS人岗匹配':'match',
      'SAAS-简历分析':'analyse',
    }
    if(record.type === "智能简历(SaaS版)"){
      record.type = 'SAAS简历'
    }
    if(record.type === "智能PPT(SaaS版)"){
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp/aigcExperience");
      window.location.reload();
      return
    } else if(record.type === "SAAS简历"){
      const type = saasresume[record.api_list[0].name]
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp/saasResume?type="+type);
      window.location.reload();
      return
    }
    newGetappList({id:record.show_id}).then((res) => {
      if(res.code !== 200) return
      if(res.data.api_list.length > 0){
        // if(this.state.identity === "PERSONAL"){
          switch (record.type){
            case '智能PPT(API版)':
              history.push("/myapp/interfacetest")
              break
            case '智能简历':
              //检查智能简历应用中是否有选中体验页面的api功能
              if(this.state.identity === "PERSONAL"){
                history.push("/myapp/resumeDocument")
              } else {
                if(this.checkResum(res.data.api_list)){
                  history.push("/myapp/interfacetest")
                } else {
                  history.push("/myapp/resumeDocument")
                }
              }
              // 简历续写/myapp/productExperience
              break
              case '智能营销':
                history.push("/myapp/enteringGinseng")
                // /myapp/enteringGinseng /myapp/writing
                break
            case '智能合同':
              history.push("/myapp/smartContract")
              break
            default:
              history.push("/")
          }
        // } else {
        //   history.push("/myapp/interfacetest")
        // }
     
      } else {
        history.push("/myapp/addmyapp")
        this.setState({
          current:2,
          model_capability:model[record.type]
        })
        let Selectoptions = {}
         this.state.createTypeList.map((item,index)=>{
          Selectoptions[item.type] = item
        })
        localStorage.setItem("Selectoptions", JSON.stringify(Selectoptions));
      }
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      window.location.reload();
    })
   
    return
    if (record.template_name === "COMMON") {
      history.push("/myapp/globalModle");

      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
      window.location.reload();
      return;
    }
    if (record.model_capability === "Interaction") {
      history.push("/myapp/question");
    } else if (record.model_capability === "Continuation") {
      history.push("/myapp/productExperience");
    } else if (record.model_capability === "SemanticPrototype") {
      history.push("/myapp/semanticprototype");
    } else if (record.model_capability === "Ner") {
      history.push("/myapp/entityExtraction");
    } else if (record.model_capability === "SmartResume") {
      history.push("/myapp/resumeDocument");
    } else if (record.model_capability === "textPolish") {
      history.push("/myapp/embroidery");
    } else if (
      record.model_capability === "writing" ||
      record.model_capability === "textsummarization"
    ) {
      history.push("/myapp/writing");
    } else if (record.model_capability === "SemanticWordCloud") {
      history.push("/myapp/wordCloud");
    } else if (record.model_capability === "TextWithPictures") {
      history.push("/myapp/image2text");
    } else if (record.model_capability === "KnowledgeJd") {
      history.push("/myapp/knowledge");
    } else if (record.model_capability === "ResumeEditor") {
      history.push("/myapp/smarteditor");
    } else if (record.model_capability === "Classification") {
      history.push("/myapp/wordClass");
    } else if (record.model_capability === "Correction") {
      history.push("/myapp/wordErrorReset");
    } else if (record.model_capability === "SmartContract") {
      history.push("/myapp/smartContract");
    } else if (record.model_capability === "SentimentAnalysis") {
      history.push("/myapp/emotionClass");
    } else if (record.model_capability === "SensitiveWords") {
      history.push("/myapp/sensitiveWords");
    } else if (record.model_capability === "Similarity") {
      history.push("/myapp/similarity");
    } else if (record.model_capability === "KnowledgeReasoning") {
      history.push("/myapp/knowledgereasoning");
    }
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    history.push("/myapp/interfacetest")
    window.location.reload();
  }
  setVisible2(value) {
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    let currentTab = this.state.listoff[0];
    this.setState({
      visible2: value,
      group_name: value ? currentTab.title : "",
      sceneKey: value ? currentTab.link_key : "",
      activeTab: "0",
      officialListTabl: [],
      officialpagination: pagination,
    });
  }
  getbalance() {
    getbalance().then((res) => {
      if (res.code === 200) {
        this.setState({
          available_limit:res.data.available_limit, //可用余额
          credit_limit:res.data.credit_limit, //信用余额
        });
      }
    });
  }
  setVisibleModel(value) {
    let pagination = this.state.footpagination;
    pagination.current = 1;
    let currentTab = this.state.listoff[0];
    this.setState({
      ModeVisible: value,
      group_name: value ? currentTab.title : "",
      activeTab: "0",
      sceneKey: value ? currentTab.link_key : "",
      myModelList: [],
      footpagination: pagination,
    });
    // setTimeout(() => {
    //   this.getModelPopuList();
    // }, 100);
  }
  onClickTab(value) {
    let tablevalue = this.state.listoff[value];
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    this.setState({
      activeTab: value,
      group_name: tablevalue ? tablevalue.title : "",
      officialListTabl: [],
      officialpagination: pagination,
      TabscrollLoading: true,
    });
    this.state.sceneKey = tablevalue ? tablevalue.link_key : "";
    this.state.group_name = tablevalue ? tablevalue.title : "";
  }
  fetchData = (currentPage) => {
    let pagination = this.state.officialpagination;
    if (pagination.total === this.state.officialListTabl.length) {
      this.setState({
        TabscrollLoading: "没有更多",
        TablLoading: false,
      });
    } else {
      let pagination = this.state.officialpagination;
      pagination.current = currentPage;
    }
  };
  getofficialappList(all, loading) {
    let page = {
      per_page: this.state.officialpagination.pageSize,
      page: this.state.officialpagination.current,
      scene: this.state.sceneKey,
    };
    // if (this.state.group_name) {
    //   page.group_name = this.state.group_name;
    // }
    this.setState({
      TablLoading: true,
    });
    getofficialappList(all ? null : page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.officialpagination;
        pagination.total = list.count;
        let officialList = [];

        if (list.count !== officialList.length || pagination.current === 1) {
          if (this.state.visible2) {
            officialList = this.state.officialListTabl.concat(list.data);
          } else {
            officialList = list.data;
          }
          if (this.state.visible2) {
            this.setState({
              officialListTabl: officialList,
            });
          } else {
            this.setState({
              officialList: officialList,
            });
          }
          this.setState({
            officialpagination: pagination,
            TablLoading: false,
          });
        } else {
          this.setState({
            TablLoading: false,
            officialpagination: pagination,
          });
        }
        if (officialList.length > 0 && !localStorage.getItem("isSuspension")) {
          localStorage.setItem("isSuspension", true);
        }
        if (officialList.length === 0) {
          localStorage.setItem("isSuspension", false);
        }
        if (!this.state.visible2) {
          setTimeout(
            () => {
              let officialScroll = this.state.officialScroll;
              if (list.count === 0 || list.count === officialList.length) {
                officialScroll.scrollLoading = "没有更多";
                officialScroll.swiperloading = false;
                this.setState({
                  officialScroll: officialScroll,
                });
              }
              if (list.count > 0) {
                let Width = this.officialRef.current?.scrollWidth;
                let clientWidth = this.officialRef.current?.clientWidth;
                if (loading) {
                  this.officialRef.current.scrollLeft += clientWidth;
                }
                let scrollLeft = this.officialRef.current?.scrollLeft;
                let scrolltype =
                  this.officialRef.current?.clientWidth + scrollLeft;
                officialScroll.scrolltype1 = scrolltype;
                officialScroll.scrollWidth = Width;
                officialScroll.scrollLeft = scrollLeft;
                officialScroll.swiperloading = false;
                console.log(officialScroll,'第二个');
                this.setState({
                  officialScroll: officialScroll,
                });
              }
            },
            loading ? 500 : 200
          );
        }
      } else {
        if (!this.state.visible2) {
          let officialScroll = this.state.officialScroll;
          officialScroll.swiperloading = false;
          this.setState({
            officialScroll: officialScroll,
          });
        }
        this.setState({
          TablLoading: false,
        });
        localStorage.setItem("isSuspension", false);
      }
    });
  }
  renderContainer(item, index) {
    const { officialListTabl } = this.state;
    return (
      <List
        grid={{
          sm: 24,
          md: 12,
          lg: 8,
          xl: 6,
        }}
        // style={{ maxHeight: 620 }}

        loading={this.state.TablLoading}
        scrollLoading={this.state.TabscrollLoading}
        onReachBottom={(currentPage) => this.fetchData(currentPage)}
        dataSource={officialListTabl}
        bordered={false}
        noDataElement={
          <Empty imgSrc={Emptydata_img} description={"暂无体验官方应用"} />
        }
        render={(item, index) => (
          <List.Item key={index} onClick={this.goOffdetails.bind(this, item)}>
            {
              <div
                className="ApplicationList-item grid-item columnSpaceBetween"
                key={index}
              >
                <div className="grid-header">
                  <div
                    className="grid-top"
                    style={{
                      backgroundColor: item.color,
                      backgroundImage: item.image_url
                        ? "url(" + item.image_url + ")"
                        : "",
                    }}
                  >
                    <Space className="grid-top-title" direction="vertical">
                      <Typography.Title heading={5} ellipsis={true}>
                        {item.name}
                      </Typography.Title>

                      <Typography.Paragraph ellipsis={true}>
                        {item.desc}
                      </Typography.Paragraph>
                    </Space>
                  </div>
                </div>

                <div>
                  <Typography.Title heading={6} ellipsis={true}>
                    <Divider
                      type="vertical"
                      className="divider-name"
                      style={{ borderColor: item.color }}
                    />

                    {item.service_options}
                  </Typography.Title>

                  <Typography.Paragraph
                    ellipsis={true}
                    className="Typography-desc"
                  >
                    {item.label}
                  </Typography.Paragraph>
                </div>

                <div className="grid-bottom">
                  <Divider />
                  <div className="rowSpaceBetween">
                    <Space>
                      <span
                        className="dispose_icon"
                        style={{
                          backgroundImage:
                            "url(" +
                            (item.group_name === "智能写作"
                              ? icon1
                              : item.group_name === "智能图文"
                              ? icon2
                              : item.group_name === "智能文档"
                              ? icon3
                              : item.group_name === "智能创作"
                              ? icon4
                              : item.group_name === "知识图谱"
                              ? icon5
                              : "") +
                            ")",
                        }}
                      ></span>

                      <span>{item.group_name}</span>
                    </Space>

                    <Button
                      type="outline"
                      onClick={this.goOffdetails.bind(this, item)}
                      className="open-now"
                    >
                      立即体验 <IconRight />{" "}
                    </Button>
                  </div>
                </div>
              </div>
            }
          </List.Item>
        )}
      />
    );
  }
  getModelPopuList() {
    let page = {
      per_page: this.state.footpagination.pageSize,
      page: this.state.footpagination.current,
      // group_name: this.state.group_name,
      from: "commend",
      train_status: "训练成功",
      scene: this.state.sceneKey,
    };
    this.setState({
      TablLoading: true,
    });
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.footpagination;
        pagination.total = list.count;
        let datas = this.state.myModelList.concat(list.data);
        this.setState({
          footpagination: pagination,
          myModelList: datas,
          loadingbody: false,
          TablLoading: false,
        });
      } else {
        this.setState({
          TablLoading: false,
        });
      }
    });
  }
  onClickTabModel(value) {
    let tablevalue = this.state.listoff[value];
    let pagination = this.state.footpagination;
    pagination.current = 1;
    this.setState({
      activeTab: value,
      group_name: tablevalue ? tablevalue.title : "",
      myModelList: [],
      footpagination: pagination,
      TabscrollLoading: true,
      sceneKey: tablevalue ? tablevalue.link_key : "",
    });
    this.state.sceneKey = tablevalue ? tablevalue.link_key : "";
    this.state.group_name = tablevalue ? tablevalue.title : "";
    // this.getModelPopuList();
  }
  fetchDataModelPopu = (currentPage) => {
    let pagination = this.state.footpagination;
    if (pagination.total === this.state.myModelList.length) {
      this.setState({
        TabscrollLoading: "没有更多",
        TablLoading: false,
      });
    } else {
      let pagination = this.state.footpagination;
      pagination.current = currentPage;
      // this.getModelPopuList();
    }
  };
  setinfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data;
        localStorage.setItem("userinfo", JSON.stringify(userinfodata));
        this.setState({
          identity: userinfodata.group_permission,
          userinfodata: userinfodata,
        });
        this.gitconfig();
        // this.getlist();
        this.getcreateappList();
        this.getbalance();
        this.getpackages();
        this.getappList();
        this.getuserpackage();
        this.getpackage();
        // this.getmodelListVersion();
        this.getboard();
        this.getHostApi();
        // this.getmodelList();
        this.getApiClass();
        // clearInterval(this.state.modelTimr);
        // this.state.modelTimr = setInterval(() => {
        //   this.getmodelListVersion();
        // }, 6000);

        if (
          userinfodata?.group_permission &&
          userinfodata?.group_permission !== "PERSONAL"
        ) {
          this.setState({
            // imageSrc: [require("asserts/vip_home_img.png")],
            imageSrc: [require("asserts/user_home_img.png")],
          });
        } else {
          this.setState({
            imageSrc: [require("asserts/user_home_img.png")],
          });
        }
      }
    });
  }
  gitconfig() {
    getconfig({ key: "product-style-setting" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let productstyle = list.value;
        this.setState({
          styleCss: productstyle,
        });
        localStorage.setItem("productstyle", JSON.stringify(productstyle));
      }
    });
    getconfig({ key: "model-capabilities" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        this.setState({
          Selectoptions: obj,
        });
        localStorage.setItem("Selectoptions", JSON.stringify(obj));
      }
    });
    getconfig({ key: "model-train-mode" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let PatternList = list.value;
        localStorage.setItem("PatternList", JSON.stringify(PatternList));
      }
    });
    getconfig({ key: "scene-setting" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let listoff = list.value;
        this.setState({
          listoff: listoff,
        });
        localStorage.setItem("Listoff", JSON.stringify(listoff));
      }
    });
    getconfig({ key: "product-list" }).then((res) => {
      let list = res.data;
      localStorage.setItem("product-list", JSON.stringify(list.value));
    });
  }
  getboard() {
    getboard().then((res) => {
      if (res.code === 200) {
        let list = res.data;
        // let top_data = list.all.top_data;
        // let dataBarEcharts = {
        //   x: [],
        //   y: [],
        // };
        // top_data.map((i, index) => {
        //   dataBarEcharts.x.push(i.key);
        //   dataBarEcharts.y.push(i.value);
        // });
        this.setState({
          board: list,
          // dataBarEcharts: dataBarEcharts,
        });
      }
    });
  }
  getlist(loading) {
    let page = {
      per_page: this.state.myapppagination.pageSize,
      page: this.state.myapppagination.current,
    };
    getappList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;  
        let pagination = this.state.myapppagination;
        pagination.total = list.count;
        let datas = this.state.myappList.concat(list.data);
        if (list.count === 0) {
          clearInterval(this.state.modelTimr);
        }
        this.setState({
          myappList: datas,
          myapppagination: pagination,
          isapp: list.count,
          loadingbody: false,
        });
        setTimeout(
          () => {
            if (list.count === 0) {
              this.setState({
                scrollLoading: "没有更多",
                swiperloading: false,
              });
            } else if (list.count === datas.length) {
              this.setState({
                scrollLoading: "没有更多",
                swiperloading: false,
              });
            }
            if (list.count > 0) {
              let Width = this.statusLineRef.current?.scrollWidth;
              // let lastChildOff =

              //   this.statusLineRef.current.lastChild.offsetLeft;

              // let lastChildWidth =

              //   this.statusLineRef.current.lastChild.clientWidth;

              let clientWidth = this.statusLineRef.current?.clientWidth;
              if (loading) {
                this.statusLineRef.current.scrollLeft += clientWidth;
              }
              let scrollLeft = this.statusLineRef.current?.scrollLeft;
              let scrolltype =
                this.statusLineRef.current?.clientWidth + scrollLeft;
              //console.log(scrolltype, Width, "加载Width");

              this.setState({
                scrolltype1: scrolltype,
                scrollWidth: Width,
                scrollLeft: scrollLeft,
                swiperloading: false,
              });
            }
          },
          loading ? 500 : 0
        );

        //console.log(list, "list");
      } else {
        this.setState({
          swiperloading: false,
          loadingbody: false,
        });
      }
    });
  }
  onswiperloading(type, name, e) {
    if (name === "model") {
      let pagination = this.state.footpagination;
      let Width = this.modelLineRef.current.scrollWidth;
      let clientWidth = this.modelLineRef.current.clientWidth;
      let modelScroll = this.state.modelScroll;
      if (type === 0) {
        this.modelLineRef.current.scrollLeft -= clientWidth;
      } else {
        if (pagination.total !== this.state.myomdelList.length) {
          pagination.current += 1;
          modelScroll.swiperloading = true;
          this.setState({
            footpagination: pagination,
            modelScroll: modelScroll,
          });
          // this.getmodelList(true);
        } else {
          this.modelLineRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.modelLineRef.current.scrollLeft;
      let scrolltype = this.modelLineRef.current.clientWidth + scrollLeft;
      modelScroll.scrolltype1 = scrolltype;
      modelScroll.scrollWidth = Width;
      modelScroll.scrollLeft = scrollLeft;
      this.setState({
        modelScroll: modelScroll,
      });
    } else if (name === "official") {
      let pagination = this.state.officialpagination;
      let Width = this.officialRef.current.scrollWidth;
      let clientWidth = this.officialRef.current.clientWidth;
      let officialScroll = this.state.officialScroll;
      if (type === 0) {
        this.officialRef.current.scrollLeft -= clientWidth;
      } else {
        if (pagination.total !== this.state.officialList.length) {
          pagination.current += 1;
          officialScroll.swiperloading = true;
          this.setState({
            officialpagination: pagination,
            officialScroll: officialScroll,
          });
        } else {
          this.officialRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.officialRef.current.scrollLeft;
      let scrolltype = this.officialRef.current.clientWidth + scrollLeft;
      officialScroll.scrolltype1 = scrolltype;
      officialScroll.scrollWidth = Width;
      officialScroll.scrollLeft = scrollLeft;
      this.setState({
        officialScroll: officialScroll,
      });
    } else if (name === "modelnew") {
      let pagination = this.state.pagination;
      let Width = this.modelNewRef?.current.scrollWidth;
      let clientWidth = this.modelNewRef?.current.clientWidth;
      let modelNewScroll = this.state.modelNewScroll;
      // console.log(
      //   pagination.total,
      //   this.state.modelProgress.length,
      //   "this.state.modelProgress.length"
      // );

      if (type === 0) {
        if (this.modelNewRef) {
          this.modelNewRef.current.scrollLeft -= clientWidth;
          this.setState({
            pagination: pagination,
            modelNewScroll: modelNewScroll,
          });
        }
      } else {
        if (
          pagination.total !== this.state.modelProgress.length &&
          pagination.total < this.state.modelProgress.length
        ) {
          pagination.current += 1;
          modelNewScroll.swiperloading = true;
          this.setState({
            pagination: pagination,
            modelNewScroll: modelNewScroll,
          });
          // this.getmodelListVersion(true);
        } else {
          this.modelNewRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.modelNewRef.current?.scrollLeft;
      let scrolltype = this.modelNewRef.current?.clientWidth + scrollLeft;
      modelNewScroll.scrolltype1 = scrolltype;
      modelNewScroll.scrollWidth = Width;
      modelNewScroll.scrollLeft = scrollLeft;
      this.setState({
        modelNewScroll: modelNewScroll,
      });
    } else {
      let myapppagination = this.state.myapppagination;
      let Width = this.statusLineRef.current.scrollWidth;
      let clientWidth = this.statusLineRef.current.clientWidth;
      if (type === 0) {
        this.statusLineRef.current.scrollLeft -= clientWidth;
      } else {
        if (myapppagination.total !== this.state.myappList.length) {
          myapppagination.current += 1;
          this.setState({
            myapppagination: myapppagination,
            swiperloading: true,
          });
          // this.getlist(true);
        } else {
          this.statusLineRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.statusLineRef.current.scrollLeft;
      let scrolltype = this.statusLineRef.current.clientWidth + scrollLeft;
      this.setState({
        scrolltype1: scrolltype,
        scrollWidth: Width,
        scrollLeft: scrollLeft,
      });
    }
  }

  getmodelListVersion(loading) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      sort: this.state.pagination.sort,
    };
    getmodelListVersion(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        let datas = list.data;
        // if (list.count !== datas.length) {
        //   datas = this.state.modelProgress.concat(list.data);
        // }

        if (list.count === 0) {
          no_data = true;
          clearInterval(this.state.modelTimr);
        } else {
          no_data = false;
        }
        this.setState({
          no_data: no_data,
          data2: list.data,
          modelProgress: datas,
          pagination: pagination,
          loading: false,
        });
        var time = setTimeout(
          () => {
            let modelNewScroll = this.state.modelNewScroll;
            if (list.count === 0 || list.count === datas.length) {
              modelNewScroll.scrollLoading = "没有更多";
              modelNewScroll.swiperloading = false;
              this.setState({
                modelNewScroll: modelNewScroll,
              });
            }
            if (list.count > 0) {
              setTimeout(() => {
                let Width = this.modelNewRef?.current?.scrollWidth;
                let clientWidth = this.modelNewRef?.current?.clientWidth;
                if (loading) {
                  this.modelNewRef.current.scrollLeft += clientWidth;
                }
                let scrollLeft = this.modelNewRef?.current?.scrollLeft;
                let scrolltype =
                  this.modelNewRef.current?.clientWidth + scrollLeft;
                modelNewScroll.scrolltype1 = scrolltype;
                modelNewScroll.scrollWidth = Width;
                modelNewScroll.scrollLeft = scrollLeft;
                modelNewScroll.swiperloading = false;
                this.setState({
                  modelNewScroll: modelNewScroll,
                });
              }, 100);
            }
            clearTimeout(time);
          },
          loading ? 500 : 0
        );
      } else {
        let modelNewScroll = this.state.modelNewScroll;
        modelNewScroll.swiperloading = false;
        this.setState({
          modelNewScroll: modelNewScroll,
          loadingbody: false,
        });
      }
    });
  }
  getpackage() {
    let list = [];
    allpackage().then((res) => {
      if (res.code === 200) {
        let no_package = false;
        Object.keys(res.data).forEach(function (item) {
          res.data[item].forEach(function (key) {
            if (key.status === 1) {
              list.push(key);
            }
          });
        });
        Object.keys(res.data).forEach(function (item) {
          res.data[item].forEach(function (key) {
            if (key.status === 0) {
              list.push(key);
            }
          });
        });
        list = list.slice(0, 9);
        if (list.length === 0) {
          no_package = true;
        } else {
          no_package = false;
        }
        this.setState({
          no_package: no_package,
          data: list,
          packageLoading: false,
        });
      } else {
        this.setState({
          packageLoading: false,
        });
      }
    });
  }
  onChangeTablePackage(packagePagination) {
    const { current, pageSize } = packagePagination;
    this.setState({
      packageLoading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.packagePagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        packagePagination: setPagination,
      });
      this.getpackage();
    }, 1000);
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.pagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        pagination: setPagination,
      });
      // this.getmodelListVersion();
    }, 1000);
  }
  godetails(record) {
    history.push("/enginefactory/model/management");
    window.location.reload();
    localStorage.setItem("record", JSON.stringify(record));
  }
  godebug(record) {
    history.push("/myapp/appdataset");
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
  }
  onRadioGroup(value) {
    this.setState({
      model_capability: value,
    });
    // history.push('/smarttext/modeltextgeneration/debugging')

    // window.location.reload();
  }
  onChangeGroup(e) {
    // 阻止事件冒泡，（阻止这个合成事件，往document上冒泡，因此不会触发click方法）

    e.nativeEvent.stopImmediatePropagation();
    // // 阻止合成事件间的冒泡，不会往最外层的div的test方法冒了，如果不加这句代码，就会冒泡至外层div，执行test方法。

    e.stopPropagation();
    history.push("/myapp/addmyapp");
    localStorage.setItem("props", JSON.stringify(this.state));
    localStorage.removeItem("record");
    window.location.reload();
    //console.log(this.state.model_capability, "onChangeGroup");
  }
  RadioGroupLeave() {
    this.setState({
      model_capability: "",
    });
  }
  createListModel(item){
    let model = {
      '智能PPT':'ai_ppt',
      '智能PPT(SaaS版)':'saas_ppt',
      '智能简历(SaaS版)':'saas_resume',
      'SAAS简历':'saas_resume',
      '智能简历':'ai_resume',
      '智能营销':'ai_marketing',
      '智能合同':'ai_pact',
    }
    const record ={
      ...item,
      type: item.class,
      model_capability: model[item.class],
      isflow: true,
    }
    history.push("/myapp/addmyapp");
    this.state.model_capability = model[item.class];
    let Selectoptions = {}
     this.state.createTypeList.map((item,index)=>{
      Selectoptions[item.type] = item
    })
    this.state.checkedtype = [item.id]
    localStorage.setItem("Selectoptions", JSON.stringify(Selectoptions));
    localStorage.setItem("props", JSON.stringify(this.state));
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
    
  }
  goMoreMyApp(record, e) {
    const { Selectoptions } = this.state;
    if (record) {
      if (
        record &&
        Selectoptions[record.model_capability].type === 3 &&
        record.model_capability === "ResumeEditor"
      ) {
        history.push("/myapp/smarteditor");
      } else {
        if (record.train_status === "训练成功") {
          if (record.template_name === "COMMON") {
            history.push("/myapp/globalModle");
            localStorage.setItem("record", JSON.stringify(record));
            localStorage.setItem("props", JSON.stringify(this.state));
            window.location.reload();
            return;
          }
          if (record.model_capability === "Interaction") {
            history.push("/myapp/question");
          } else if (record.model_capability === "Continuation") {
            history.push("/myapp/productExperience");
          } else if (record.model_capability === "SemanticPrototype") {
            history.push("/myapp/semanticprototype");
          } else if (record.model_capability === "Ner") {
            history.push("/myapp/entityExtraction");
          } else if (record.model_capability === "SmartResume") {
            history.push("/myapp/resumeDocument");
          } else if (record.model_capability === "textPolish") {
            history.push("/myapp/embroidery");
          } else if (
            record.model_capability === "writing" ||
            record.model_capability === "textsummarization"
          ) {
            let moduleIdType = JSON.parse(localStorage.getItem("moduleIdType"));
            let arr = moduleIdType["writing"];
            if (arr.indexOf(record.model_id) !== -1) {
              history.push("/myapp/writingcurrency");
            } else {
              history.push("/myapp/writing");
            }
          } else if (record.model_capability === "SemanticWordCloud") {
            history.push("/myapp/wordCloud");
          } else if (record.model_capability === "TextWithPictures") {
            history.push("/myapp/image2text");
          } else if (record.model_capability === "KnowledgeJd") {
            history.push("/myapp/knowledge");
          } else if (record.model_capability === "Classification") {
            history.push("/myapp/wordClass");
          } else if (record.model_capability === "Correction") {
            history.push("/myapp/wordErrorReset");
          } else if (record.model_capability === "SmartContract") {
            history.push("/myapp/smartContract");
          } else if (record.model_capability === "SentimentAnalysis") {
            history.push("/myapp/emotionClass");
          } else if (record.model_capability === "SensitiveWords") {
            history.push("/myapp/sensitiveWords");
          } else if (record.model_capability === "Similarity") {
            history.push("/myapp/similarity");
          } else if (record.model_capability === "KnowledgeReasoning") {
            history.push("/myapp/knowledgereasoning");
          }  else if (record.model_capability === "AIGC") {
            history.push("/myapp/aigcExperience");
          }
          else if (record.model_capability === "CopydoneArticle") {
            history.push("/myapp/copydoneArticle");
          }
        } else {
          history.push("/myapp/addmyapp");
          record.train_details = "详情";
        }
      }
      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
    } else {
      let record = {
        deployment: false,
      };
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp");
    }
    window.location.reload();
  }
  onMouseEnter(index) {
    this.setState({ hoverindex: index });
  }
  onMouseLeave(index) {
    this.setState({
      editTypography: "",
      editTypographydesc: "",
      hoverindex: "",
    });
  }
  goJump(value) {
    history.push(value);
    window.location.reload();
  }
  isputapp = (name, desc, id) => {
    this.putapp(name, desc, id);
  };
  putapp(name, desc, id) {
    let params = {
      id: id,
    };
    if (desc) {
      params.desc = desc;
    } else if (name) {
      params.name = name;
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
        const tmpData = this.state.myappList;
        if (desc) {
          for (var i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].desc = desc;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        } else if (name) {
          for (let i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].name = name;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        }
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onChangetitle(type, id, value) {
    //console.log(type, id, value, "修改?");

    if (
      (value.length > 20 && type === "name") ||
      (value.length > 56 && type === "desc")
    ) {
      Notification.warning({ content: "修改内容超过32个字符！" });
      return;
    } else if (value === "") {
      Notification.warning({ content: "修改内容不能为空！" });
      return;
    }
    if (type === "desc") {
      this.isputapp(false, value, id);
    } else if (type === "name") {
      this.isputapp(value, false, id);
    }
  }
  onseTypographyn(item, type, e) {
    e.stopPropagation();
    this.setState({
      editTypography: type === 1 ? item.id : "",
      editTypographydesc: type === 2 ? item.id : "",
    });
    setTimeout(() => {
      if (this.state.editTypographydesc) {
        //TextArea光标位置不准确问题

        this.getFocusDom(e, item);
      } else {
        document.getElementById(item.id).focus();
      }
    }, 100);
    //console.log(e, "onseTypographyn");
  }
  getFocusDom = (e, record) => {
    var fDOM = document.getElementById(record.id);
    var len = record.desc.length;
    this.setSelectionRange(fDOM, len, len);
  };
  setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
  };
  onblur(id, e) {
    //console.log(e.target.value.length, "onblur");

    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  goManagement() {
    history.push("/personalcenter/membermanagement");
    history.go();
  }
  EnterTypography(id, e) {
    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  setVisible(value) {
    this.setState({
      visible: value,
    });
  }
  openDate(event) {
    this.stopPropagation(event);
    this.setState({
      ishowdata: !this.state.ishowdata,
    });
  }
  onClickdate(event) {
    this.stopPropagation(event);
  }
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation();
  }
  setValue(v) {
    this.state.value = v;
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      value: v,
      pagination: packagePagination,
    });
    this.getpackages();
    //console.log(v);
  }
  setDates(dateString) {
    this.setState({
      rangeValue: dateString,
    });
  }
  onOk(dateString, date) {
    //console.log(date, "event");
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      postrangeValue: dateString,
      value: "",
      ishowdata: !this.state.ishowdata,
      pagination: packagePagination,
    });
    this.getpackages();
  }
  onChange(dateString) {
    this.state.postrangeValue = dateString ? dateString : ["", ""];
    this.state.value = "";
    this.setState({
      rangeValue: dateString,
      value: "",
    });
  }
  callSelect(value) {
    this.setState({
      postValue: value == undefined ? 'all' : value
    })
    setTimeout(()=>{
      this.getpackages();
    },400)
  }
  getpackages() {
    let type = "";
    if (this.state.value === "今日") {
      type = "today";
    } else if (this.state.value === "昨日") {
      type = "yesterday";
    } else if (this.state.value === "近7日") {
      type = "7days";
    } else if (this.state.value === "近30日") {
      type = "30days";
    }
    let page = {
      // per_page: this.state.pagination.pageSize,
      // page: this.state.pagination.current,
      dateType: type, //today、yesterday、7days、30days
      app_id: this.state.postValue,
      start_date: this.state.value ? '' : this.state.postrangeValue[0],
      end_date: this.state.value ? '' : this.state.postrangeValue[1],
      type:this.state.postValue == 'all' ? "total" : "list",
    };
    if(this.state.postValue == 'all'){
      delete page.app_id;
    }
    this.setState({
      no_callData:true,
    });
    call_statistics(page).then((res) => {
      if (res.code === 200) {
        // let no_data = false;
        let Date = [];
        let total = [];
        let single = [];
        let newcallData = this.state.callData;
        Date = this.generateDates(type,page.start_date,page.end_date);
        total = new Array(Date.length).fill(0);
        newcallData.x = Date;
        newcallData.y1 = total;

        if(res.data.length > 0){
          let list = res.data;
            list.map((item) => {
              Date.map((date, index) => {
                if(item.date == date){
                  total[index] = item.total;
                }
              })
              // Date.push(item.date);
              // total.push(item.total);
              // single.push(item.single);
            });
            // newcallData.x = Date;
            // newcallData.y1 = total;
            this.setState({
              callData: newcallData,
              no_callData:false,
            });
        } else {
      
          this.setState({
            callData: newcallData,
            no_callData:false,
          })
          // this.setState({
          //   loadingbody: false,
          //   loading: false,
          //   no_data: true,
          // })
        }
   
        // let packagePagination = this.state.pagination;
        // packagePagination.total = list.all_count;
        // if (list.all_count === 0) {
        //   no_data = true;
        // } else {
        //   no_data = false;
        // }
        // this.setState({
        //   no_data: no_data,
        //   data: list.data,
        //   pagination: packagePagination,
        //   loading: false,
        //   loadingbody: false,
        // });
      } else {
        this.setState({
          loadingbody: false,
          loading: false,
          no_data: true,
          data: [],
        });
      }
    });
  }

  generateDates(dateType, start_date, end_date) {
    function parseDate(dateStr) {
      const parts = dateStr.split(' ');
      const datePart = parts[0].split('-');
      const timePart = parts[1].split(':');
      return new Date(datePart[0], datePart[1] - 1, datePart[2], timePart[0], timePart[1], timePart[2]);
    }
  
    if (dateType) {
      const currentDate = new Date();
  
      switch (dateType) {
        case 'today':
          return [`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`].reverse();
        case 'yesterday':
          currentDate.setDate(currentDate.getDate() - 1);
          return [`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`].reverse();
        case '7days':
          let dates7 = [];
          for (let i = 0; i < 7; i++) {
            let tempDate = new Date(currentDate);
            tempDate.setDate(currentDate.getDate() - i);
            dates7.push(`${tempDate.getFullYear()}-${String(tempDate.getMonth() + 1).padStart(2, '0')}-${String(tempDate.getDate()).padStart(2, '0')}`);
          }
          return dates7.reverse();
        case '30days':
          let dates30 = [];
          for (let i = 0; i < 30; i++) {
            let tempDate = new Date(currentDate);
            tempDate.setDate(currentDate.getDate() - i);
            dates30.push(`${tempDate.getFullYear()}-${String(tempDate.getMonth() + 1).padStart(2, '0')}-${String(tempDate.getDate()).padStart(2, '0')}`);
          }
          return dates30.reverse();
        default:
          throw new Error('Invalid dateType provided.');
      }
    } else {
      const startDate = parseDate(start_date);
      const endDate = parseDate(end_date);
      const dates = [];
  
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
        currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // 增加一天
      }
  
      return dates;
    }
  }
  
  //选择创建应用的点击方法
  selestCreate(item,index){
    this.setState({
      selectApply:index
    })
  }
  onlidel(key, e) {
    e.stopPropagation();
    let selectedRowKeys = [key];
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
    this.setVisible(true);
  }
  deleteapp() {
    //console.log(this.state.selectedRowKeys, "selectedRowKeysselectedRowKeys");

    this.setState({
      confirmLoading: true,
    });
    let myapppagination = this.state.myapppagination;
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    deleteapp(datas).then((res) => {
      if (res.code === 200) {
        myapppagination.pageSize = 5;
        myapppagination.current = 1;
        this.setState({
          myappList: [],
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          myapppagination: myapppagination,
        });
        this.setVisible(false);
        // this.getlist();
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  Imageinit(avatar, avatarid, domid) {
    if (avatar) {
      Imageinit(avatar, JQ("#" + avatarid), JQ("#" + domid), 2);
    }
  }
  listMyApp() {
    const {
      editTypographydesc,
      editTypography,
      Selectoptions,
      myappList,
      checkbox,
      styleCss,
      newappList,
    } = this.state;
    return newappList.map((item, index) => {
      let styleObj = styleCss[item.group_name];
      return (
        <div
          className="ApplicationList-item grid-item"
          style={{
            backgroundImage: this.randomColor(item.type)?.gradientcolor,
            borderColor: this.randomColor(item.type)?.color,
          }}
          onClick={
            this.goOffdetails.bind(this, item)
          }
          key={index}
          onMouseLeave={this.onMouseLeave.bind(this, index)}
          onMouseEnter={this.onMouseEnter.bind(this, index)}
        >
          <Checkbox
            key={index}
            value={item.id}
            className={`${checkbox ? `ischeckbox` : ``}`}
          >
            {/* {item.avatar ? (
              <div
                className="applistAvatar"
                id={item.id + "avatardom"}
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  alt="avatar"
                  src={item.avatar}
                  className="founder"
                  id={item.id + "avatar"}
                />

                {this.Imageinit(
                  item.avatar,
                  item.id + "avatar",
                  item.id + "avatardom"
                )}
              </div>
            ) : (
              ""
            )} */}
            <div className="rowStart apply-content">
              <div className="rowCenter apply-img">
                <img
                  alt=""
                  src={
                    this.randomColor(item.type)?.label_icon
                  }
                />
              </div>

              <div>
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.name}
                </div>

                <div className="label-modelname">应用：{item.type}</div>
              </div>
            </div>
          </Checkbox>
        </div>
      );
    });
  }
  onSwiperinit(type, e) {
    let Width = this.initRef.current.scrollWidth;
    let lastChildOff = this.initRef.current.lastChild.offsetLeft;
    let lastChildWidth = this.initRef.current.lastChild.clientWidth;
    if (type === 0) {
      this.initRef.current.scrollLeft -= lastChildWidth;
    } else {
      this.initRef.current.scrollLeft += lastChildWidth;
    }
    let scrollLeft = this.initRef.current.scrollLeft;
    let scrolltype = lastChildOff + scrollLeft + 16;
    //console.log(scrolltype, Width, "offsetLeft", scrollLeft);

    if (scrolltype > Width) {
      this.setState({
        scrolltype: "left",
      });
    } else if (scrolltype < Width) {
      this.setState({
        scrolltype: "right",
      });
    }
  }
  getmodelList(loading) {
    let page = {
      per_page: this.state.footpagination.pageSize,
      page: this.state.footpagination.current,
      from: "commend",
      train_status: "训练成功",
    };
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.footpagination;
        pagination.total = list.count;
        let datas = this.state.myomdelList.concat(list.data);
        this.setState({
          footpagination: pagination,
          myomdelList: datas,
          loadingbody: false,
        });
        setTimeout(
          () => {
            let modelScroll = this.state.modelScroll;
            if (list.count === 0 || list.count === datas.length) {
              modelScroll.scrollLoading = "没有更多";
              modelScroll.swiperloading = false;
              this.setState({
                modelScroll: modelScroll,
              });
            }
            if (list.count > 0) {
              let Width = this.modelLineRef.current?.scrollWidth;
              let clientWidth = this.modelLineRef.current?.clientWidth;
              if (loading) {
                this.modelLineRef.current.scrollLeft += clientWidth;
              }
              let scrollLeft = this.modelLineRef.current?.scrollLeft;
              let scrolltype =
                this.modelLineRef.current?.clientWidth + scrollLeft;
              modelScroll.scrolltype1 = scrolltype;
              modelScroll.scrollWidth = Width;
              modelScroll.scrollLeft = scrollLeft;
              modelScroll.swiperloading = false;
              this.setState({
                modelScroll: modelScroll,
              });
            }
          },
          loading ? 500 : 0
        );
      } else {
        let modelScroll = this.state.modelScroll;
        modelScroll.swiperloading = false;
        this.setState({
          modelScroll: modelScroll,
          loadingbody: false,
        });
      }
    });
  }
  goMoreModel() {
    history.push("/enginefactory/model");
    window.location.reload();
  }
  goMoreEngine() {
    history.push("/engineservices");
    window.location.reload();
  }
  // 最近模型
  listModel() {
    const { modelProgress } = this.state;
    return modelProgress.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.godetails.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart apply-content">
            <div className="model-content">
              <div className="rowSpaceBetween">
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.model_capability_name}模型22
                </div>

                <span>
                  {item.train_status === "训练成功" ? (
                    <IconCheckCircleFill style={{ color: "#00B42A" }} />
                  ) : item.train_status === "训练中" ? (
                    <IconLoading style={{ color: "#165dff" }} />
                  ) : item.train_status === "训练失败" ? (
                    <IconMinusCircleFill style={{ color: "#86909C" }} />
                  ) : (
                    <IconExclamationCircleFill style={{ color: "#ff7d00" }} />
                  )}
                </span>
              </div>

              <div className="model-progress">
                <Progress
                  strokeWidth={7}
                  color={
                    item.train_status === "训练失败" ? "#999999" : "#4172FF"
                  }
                  percent={item.train_percent}
                  trailColor={
                    item.train_status === "训练失败"
                      ? "#F1F1F1"
                      : "rgba(65, 114, 255, 0.2)"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  goCreativeModel(record) {
    history.push("/myapp/addmyapp");
    record.train_details = "模型创作";
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    window.location.reload();
  }
  // 创作模型
  creativeModel() {
    const { myomdelList } = this.state;
    return myomdelList.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.goCreativeModel.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart apply-content">
            <div className="model-content">
              <div className="rowStart label-name AlibabaPuHuiTi-Medium">
                <span className="dispose_icon"></span>

                {item.name}
              </div>

              <div
                className="ellipasedom model-describe"
                title={item.model_desc}
              >
                {item.model_desc}
              </div>

              <img
                src={item.model_icon ? item.model_icon : model_img}
                className="model_img"
                alt=""
              />
            </div>
          </div>
        </div>
      );
    });
  }
    // 文档类型
    creativeModelType() {
      const { identity } = this.state;
      let createTypeList =[
        {
          name:"智能PPT",
          model_desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
        },
        {
          name:"智能简历",
          model_desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
        },
        {
          name:"更多智能文档",
          model_desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_more,
        },
      ]
      // if (identity && identity == "PERSONAL"){
      //   createTypeList.splice(0,1)
      // }
      return createTypeList.map((item, index) => {
        return (
          <div
            className="ApplicationList-item grid-item createType-item"
            // onClick={this.goCreativeModel.bind(this, item)}
            key={index}
          >
            <div className="columnCenterStart apply-content">
              <div className="model-content">
                <div className="rowStart label-name AlibabaPuHuiTi-Medium">
                  {item.name}
                </div>
  
                <div
                  className="ellipasedom model-describe"
                  title={item.model_desc}
                >
                  {item.model_desc}
                </div>
  
                <img
                  src={item.model_icon ? item.model_icon : model_img}
                  className="model_img"
                  alt=""
                />
              </div>
              <div className="createList-item">
                  {/* <div
                    className="swiper-wrap"
                    ref={this.statusLineRef}
                  > */}
                    {this.createListApp(item.name) ? this.createListApp(item.name) :     <Empty
                          imgSrc={Emptydata_img}
                          description={"暂无应用列表"}
                        />}
                  </div>
              {/* </div> */}
            </div>
          </div>
        );
      });
    }

    createListApp(name) {
      const {
        Selectoptions,
        myappList,
        checkbox,
        newappApi,
        identity,
      } = this.state;
      if (identity && identity == "PERSONAL" && name == "智能PPT"){
        newappApi["智能PPT"] = [{
          "class": "智能PPT(SaaS版)",
          "desc": "在线体验chatppt",
          "name": "chatppt",
          "id": 3,
          "icon": ""
      }];
        // return false;
      } else if(identity && identity == "PERSONAL" && name == "智能简历"){
        newappApi[name] = newappApi[name].filter((item) => {
          return item.name == "简历辅写" || item.name == "简历生成"
        })
      }
      newappApi["智能简历"].unshift({
        "class": "SAAS简历",
        "desc": "在线体验简历分析报告",
        "name": "在线简历分析",
        "id": 901,
        "icon": ""
      })
      newappApi["智能简历"].unshift({
        "class": "SAAS简历",
        "desc": "在线体验岗位匹配",
        "name": "在线人岗匹配",
        "id": 902,
        "icon": ""
      })
      newappApi["智能简历"].unshift({
        "class": "SAAS简历",
        "desc": "在线体验岗位探测",
        "name": "在线岗位探测",
        "id": 903,
        "icon": ""
      })
      return newappApi[name]?.map((item, index) => {
        return (
          <div
            className="grid-item create-list-item"
            style={{
              borderColor: '#F0EBE8',
            }}
            // onClick={
            //   editTypography || editTypographydesc
            //     ? null
            //     : this.goMoreMyApp.bind(this, item)
            // }
            key={index}
            onMouseLeave={this.onMouseLeave.bind(this, index)}
            onMouseEnter={this.onMouseEnter.bind(this, index)}
          >
            <Checkbox
              key={index}
              value={item.id}
              className={`${checkbox ? `ischeckbox` : ``}`}
            >
              <div className="rowStart apply-content">
                <div className="rowCenter apply-img">
                  <img
                    alt=""
                    src={
                      Selectoptions[item?.model_capability]
                        ? Selectoptions[item.model_capability].label_icon
                        : model_img
                    }
                  />
                </div>
  
                <div>
                  <div className="label-name AlibabaPuHuiTi-Medium">
                    {item.name}
                  </div>
  
                  <div className="label-modelname">{item?.desc || '上下文解析/内容续写'}</div>
                </div>
                <div className="label-icon-right"><IconRight /></div>
                
                  <div className="mask" onClick={this.createListModel.bind(this,item)}>
                    <button className="create-app-btn" >创建应用</button>
                  </div>
              </div>
            </Checkbox>
          </div>
        );
      });
    }

  randomColor = (name)=>{
    // let colors = ['#006FBC','#004C8A','#384AB2','#69A0CA','#ff7575','#F87C94','#5E68FF','#4F96E8','#4340B9','#67C9FF']
    let style = {
      "智能PPT(API版)":{
        color:'rgb(118, 188, 233)',
        gradientcolor:"linear-gradient(rgb(211, 237, 253) 0%, rgb(255, 255, 255) 100%)",
        img:"https://saas.file.yoo-ai.com/20221103/f2022110-3150-6312-1391-9bf794df8701.png",
        label_icon:ai_ppt_api
      },
      "智能PPT(SaaS版)":{
        color:'rgb(99, 188, 120)',
        gradientcolor:"linear-gradient(rgb(201, 246, 202) 3%, rgba(255, 255, 255, 0) 100%)",
        img:"https://saas.file.yoo-ai.com/20221103/f2022110-3150-0531-a130-3e29e91e76b5.png",
        label_icon:ai_ppt_saas
      },
      "智能简历(SaaS版)":{
        color:'rgb(178, 153, 228)',
        gradientcolor:"linear-gradient(rgb(241, 233, 255) 0%, rgb(255, 255, 255) 100%)",
        img:"https://saas.file.yoo-ai.com/20221018/f2022101-8161-6550-f252-23cf8cbae5d8.png",
        label_icon:ai_marketing_icon
      },
      "智能简历":{
        color:'rgb(178, 153, 228)',
        gradientcolor:"linear-gradient(rgb(241, 233, 255) 0%, rgb(255, 255, 255) 100%)",
        img:"https://saas.file.yoo-ai.com/20221018/f2022101-8161-6550-f252-23cf8cbae5d8.png",
        label_icon:ai_marketing_icon
      },
      "智能营销":{
        color:'rgb(247, 104, 161)',
        gradientcolor:"linear-gradient(rgb(255, 227, 238) 0%, rgb(255, 255, 255) 100%)",
        img:"https://saas.file.yoo-ai.com/20221014/f2022101-4144-318e-1903-771fc93e3fdc.png",
        label_icon:ai_resume_icon
      },
      "智能合同":{
        color:'rgb(240, 188, 111)',
        gradientcolor:"linear-gradient(rgb(255, 242, 223) 7%, rgb(255, 255, 255) 98%)",
        img:"https://saas.file.yoo-ai.com/20220402/f2022040-2201-8123-1c37-ee9ccbbd87e8.png",
        label_icon:ai_pact_icon
      },

    }
    // 使用 Math.random() 和 Math.floor() 来获取一个随机索引
    // const randomIndex = Math.floor(Math.random() * colors.length);
    return style[name] || {color:'rgb(29, 88, 164)',img:"https://saas.file.yoo-ai.com/20220402/f2022040-2201-8123-1c37-ee9ccbbd87e8.png"}
  }
  // 创作应用
  listOfficialList() {
    const { officialList,newappList } = this.state;
    return newappList.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.goOffdetails.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart officia-content">
            <div className="model-content">
              <div
                className="apply-header"
                style={{
                  backgroundColor: this.randomColor(item.type).color,
                  // backgroundImage: this.randomColor(item.type).img
                  //   ? "url(" + this.randomColor(item.type).img + ")"
                  //   : "",
                }}
              >
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.name}
                </div>

                {/* <div>{item.label}</div> */}
                <div>{item.desc}</div>
              </div>

              {/* <div className="apply-desc rowStartWrap">
                <div className="ellipaseline model-describe" title={item.desc}>
                  {item.desc}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    });
  }
  // 创作服务引擎
  engineList() {
    const { data } = this.state;
    return data.map((item, index) => {
      return (
        <div
          className="rowStart card-engine-li"
          onClick={
            item.opened_package.length === 0
              ? this.goMoreEngine.bind(this, "")
              : this.onPromotion.bind(this, item)
          }
          key={index}
        >
          <div className="rowCenter engine-li-img">
            {item.opened_package.length === 0 ? (
              <img
                className="li-img-grey"
                src={item ? item.label_icon : ""}
                alt=""
              />
            ) : (
              <img
                className="li-img-black"
                src={item ? item.label_icon : ""}
                alt=""
              />
            )}
          </div>

          {item.opened_package.length === 0 ? (
            <div>
              <div className="AlibabaPuHuiTi-Medium engine-li-title-grey">
                {item.label}
              </div>

              <div className="engine-li-text-grey">暂未开通</div>
            </div>
          ) : (
            <div>
              <div className="AlibabaPuHuiTi-Medium engine-li-title-black">
                {item.label}
              </div>

              <div>余 {item.opened_package.free} 条</div>
            </div>
          )}
        </div>
      );
    });
  }
  onPromotion(res) {
    history.push("/myapp/resources");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  // onClickBtn() {
  //   return
  //   this.setState({
  //     visible3: true,
  //     Selectoptions: JSON.parse(localStorage.getItem("Selectoptions"))
  //       ? JSON.parse(localStorage.getItem("Selectoptions"))
  //       : [],
  //   });
  // }
  openEngine(){
    history.push("/engineservices");
    history.go();
  }
  onRechargen() {
    // 审核状态 PENDING-待审核 APPROVED-已审核 REJECTED-审核不通过 NOT_APPLIED-未申请
    if(this.state.userinfodata.group_permission === "PERSONAL"){
      if(this.state.userinfodata.identity === 'APPROVED'){
        history.push("/payment/accountrecharge");
        history.go();
      } else {
        history.push("/personalAuthentication");
        history.go();
      }
    } else {
      history.push("/payment/accountrecharge");
      history.go();
    }
  }
  onCancel() {
    this.setState({
      visible3: false,
    });
  }
  setVisible1(event) {
    if (event.model_capability) {
      history.replace("/myapp/addmyapp");
      localStorage.setItem("props", JSON.stringify(event));
      localStorage.removeItem("record");
      window.location.reload();
      this.onCancel();
    } else {
      Notification.warning({ content: "请选择服务类型！" });
    }
  }
  formatNumber(num) {
    if (num > 1000000) {
        return Math.floor(num / 100000) + '0W+';
    } else {
        return num.toString();
    }
}
  CreateApp(){
    let { selectApply } = this.state
    if(selectApply != -1){
      // history.replace("/myapp/addmyapp");
      // localStorage.setItem("newCreate", JSON.stringify(params));
      // localStorage.removeItem("record");
      // window.location.reload();
      this.setState({
        createVisible:false,
      })
    } else {
      Notification.warning({ content: "请选择创建的产品类型！" });
    }
  }
  goRouteJump(to) {
    history.push(to);
    history.go();
  }
  render() {
    const {
      userinfodata,
      identity,
      officialList,
      confirmLoading,
      visible3,
      visible2,
      visible,
      loadingbody,
      scrolltype1,
      scrollLeft,
      scrollWidth,
      Selectoptions,
      board,
      isapp,
      myapppagination,
      pagination,
      dataBarEcharts,
      swiperloading,
      imageSrc,
      modelScroll,
      modelNewScroll,
      officialScroll,
      ModeVisible,
      createVisible,
      Tablist,
      ishowdata,
      value,
      rangeValue,
      data,
      callData,
      no_callData,
      selectApply,
      available_limit,
      credit_limit,
      interfaceOption,
      newappList,
      packageData,
      no_open,
      no_page,
      bartable_top,
      hostList,
      all_total,
      applistLoading,
      has_enterprise
    } = this.state;
    return (
      <div className="overview">
        <Typography className="hello-title">
          <Space align="center">
            <span className="weixiaoicon"></span>

            <Title
              heading={5}
              style={{ marginBottom: 0, fontSize: 22, marginTop: 0 }}
            >
              {getTimeState() ? getTimeState() + "！" : ""}
              {userinfodata ? userinfodata.nickname : ""}
              ，今天是你开启智能创作的第
              <span className="hello-title-day AlibabaPuHuiTi-Medium">
                {board.my.join_day}
              </span>
              天
            </Title>
          </Space>
        </Typography>

        <ModalSelect
          visible={visible3}
          selectoptions={Selectoptions}
          onCancel={this.onCancel.bind(this)}
          setVisible={this.setVisible1.bind(this)}
        ></ModalSelect>

        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="rowFlexStartStart overview-modular">
              <div className="overview-modular-left">
                <Carousel
                  animation="fade"
                  showArrow="never"
                  indicatorPosition="bottom"
                  // onClick={this.onClickBtn.bind(this)}
                  style={{
                    width: "100%",
                    height: 194,
                  }}
                >
                  {imageSrc?.map((item, i) => {
                    return (
                      <div key={i}>
                        <img src={item} alt="" />
                      </div>
                    );
                  })}
                </Carousel>

                <div className="overview-modular-list">
                    <div className="rowStart AlibabaPuHuiTi-Medium modular-headline">
                      <img
                        src={
                          identity && identity !== "PERSONAL"
                            ? home_vip_icon2
                            : home_blue2_icon
                        }
                        alt=""
                        className="hed-icon"
                      />
                      智能文档
                    </div>

                    {
                      applistLoading ? <Spin loading={applistLoading} dot className="loadingbody"></Spin> :
                      (
                        newappList.length == 0 ? 
                        <>
                        <div className="swiper-list creative-model">
                            <div className="swiper-wrap" ref={this.modelLineRef}>
                            { this.creativeModelType() }
                            </div>
                        </div>
                          
                        </>
                        : 
                        <>
                          <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                              应用列表
                          </div>
                          <div className="swiper-list official-model" style={{ marginTop: '12px' }}>
                            <div className="swiper-wrap" ref={this.officialRef}>
                                {/* {newappList.length > 0 ? this.listOfficialList() : ''} */}
                                {newappList.length > 0 ? this.listMyApp() : ''}
                              </div>
                              {officialScroll.scrollLeft > 0 ? (
                                <Button
                                  shape="circle"
                                  className="circleloding left"
                                  icon={<IconLeft />}
                                  onClick={this.onswiperloading.bind(
                                    this,
                                    0,
                                    "official"
                                  )}
                                />
                              ) : null}
                              {officialScroll.scrolltype1 <
                              officialScroll.scrollWidth ? (
                                <Button
                                  shape="circle"
                                  className="circleloding"
                                  icon={<IconRight />}
                                  loading={officialScroll.swiperloading}
                                  onClick={this.onswiperloading.bind(
                                    this,
                                    1,
                                    "official"
                                  )}
                                />
                              ) : null}
                          </div>

                          <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                              应用调用数据
                          </div>
                          <Space size={"medium"} className="overview-speace" style={{ width:'100%', marginTop: '25px' }}>
                              <Radio.Group
                                type="button"
                                name="direction"
                                value={value}
                                onChange={this.setValue.bind(this)}
                                options={Tablist}
                                className="GroupTable"
                              ></Radio.Group>
                              <div
                                className="DatePicker-RangePicker"
                                onClick={this.onClickdate.bind(this)}
                              >
                                <Button
                                  shape="circle"
                                  className="custom-White-button"
                                  style={{
                                    boxShadow: "none",
                                  }}
                                  icon={<IconCalendar />}
                                  onClick={this.openDate.bind(this)}
                            
                                />
                                {ishowdata ? (
                                  <DatePicker.RangePicker
                                    triggerElement={null}
                                    showTime
                                    className="RangePicker-box"
                                    value={rangeValue}
                                    onSelect={(valueString, value) => {
                                      this.setDates(value);
                                    }}
                                    onChange={(v) => this.onChange(v)}
                                    onOk={this.onOk.bind(this)}
                                    disabledDate={(current) => {
                                      if (rangeValue && rangeValue.length && current) {
                                        const tooLate =
                                          rangeValue[0] &&
                                          Math.abs(current.diff(rangeValue[0], "day")) > 28;
                                        const tooEarly =
                                          rangeValue[1] &&
                                          Math.abs(rangeValue[1].diff(current, "day")) > 28;
                                        return tooEarly || tooLate;
                                      }
                                      return false;
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              <Select
                              className="call-select"
                                // allowClear
                                animation
                                placeholder="请选择查看的应用"
                                defaultValue="全部应用"
                                onChange={(value) =>
                                  this.callSelect(value)
                                }
                                options={interfaceOption}
                              ></Select>

                          </Space>
                          
                          <div className="linecharts">
                            {no_callData ? <Spin dot /> : 
                              <>
                                <LineEcharts data={callData} yname="单位：条" title="" />
                              </>
                            }
                          </div>
                        </>
                      )
                    }
                
                  
                </div>
              </div>

              <div className="overview-modular-right">
                <div className={`cardmodular-right ${userinfodata && userinfodata.has_enterprise ? "cardmodular-right-hasenterprise" : ''}`}>
                  <div className="cardmodular-backimg"></div>
                  <div className="modular-head-box">
                  {userinfodata.vip_level >= 1 && userinfodata.group_permission !== "PERSONAL" ? <Title heading={4}>{userinfodata ? userinfodata.enterprise_name : ''} </Title> : '' }
                    <div className="rowStart modular-head">
                      {userinfodata.vip_level >= 1 && userinfodata.group_permission !== "PERSONAL"
                          ? ''
                          : 
                          <div className="user-info-content">
                          <Avatar
                            size={74}
                            shape="circle"
                            style={{ marginRight: 17 }}
                          >
                          {userinfodata.avatar ? (
                            <img
                              alt="avatar"
                              id="headeravatar"
                              style={{ height: 'auto' }}
                              src={userinfodata.avatar}
                              onError={(e) => {
                                e.target.src = userinfodata.avatar
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Avatar>
                        <div className="user-info-content-right">
                          <div className="user-info-content-right-name">{userinfodata ? userinfodata.nickname : ''}</div>
                          <div className="user-info-content-right-time">入驻时间：{userinfodata ? userinfodata.join_time.split(' ')[0] : ''}</div>
                        </div>
                      </div>}

                      {identity && identity !== "PERSONAL" ? (
                        <>
                          <Avatar.Group
                            size={40}
                            style={{ marginRight: 0 }}
                            onClick={this.goManagement.bind()}
                          >
                            {board?.enterprise?.group_list.map(
                              (item, avatar_key) => {
                                return (
                                  <Avatar
                                    key={avatar_key}
                                    style={{
                                      backgroundColor: "#7BC616",
                                      marginRight: -10,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img alt="avatar" src={item.avatar} />
                                  </Avatar>
                                );
                              }
                            )}
                          </Avatar.Group>

                          <div className="AlibabaPuHuiTi-Medium avatar-quantity">
                            {board?.enterprise?.group_count >= 100
                              ? "99+"
                              : board?.enterprise?.group_count}
                          </div>
                        </>
                      ) : null}
                    </div>

                    {/* <div className="rowStart modular-desc">
                      <span>会员到期：{board.vip_expire_date}</span>

                      <div
                        className="AlibabaPuHuiTi-Medium modular-renew"
                        onClick={() => this.setvisibleUpgrade()}
                      >
                        续费
                      </div>

                      <div
                        className="AlibabaPuHuiTi-Medium modular-upgrade"
                        onClick={() => this.setvisibleUpgrade()}
                      >
                        {identity &&
                        identity !== "PERSONAL" &&
                        userinfodata.vip_level === 3
                          ? "定制"
                          : "升级"}
                      </div>
                    </div> */}

                    {/* <div className="identity-level">
                      {identity && identity !== "PERSONAL" ? (
                        <div
                          className={`identity-level-bg ${
                            `svip` + userinfodata.vip_level + `_icon`
                          }`}
                        ></div>
                      ) : (
                        <div
                          className={`identity-level-bg ${
                            `svip` + userinfodata.vip_level + `_bule_icon`
                          }`}
                        ></div>
                      )}
                    </div> */}
                  </div>

                  <div className="rowSpaceAround svg-module-list">
                    <div className="module-item" onClick={()=>{
                      history.push('/myapp');
                      window.location.reload();
                    }}
                    style={{cursor: 'pointer'}}
                    >
                      <div className="modul-left modul-left_app"></div>
                      <div className="modul-right">
                        <span className="modul-num">{identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.app_count.count
                                  : board?.my.app_count}</span>
                        <span className="modul-name">{userinfodata.group_permission !== "PERSONAL" ? "企业应用" : "应用数量" }</span>
                      </div>
                    </div>
                    <div className="module-item">
                    <div className="modul-left modul-left_api"></div>
                      <div className="modul-right">
                        <span className="modul-num"> {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.api_count?.count
                                  : board?.my?.api_count}  </span>
                        <span className="modul-name">{userinfodata.group_permission !== "PERSONAL" ? "企业接口" : "接口数量" }</span>
                      </div>
                    </div>

                    
                  </div>

                  {identity && identity !== "PERSONAL" ? (
                    ""
                  ) : (
                    userinfodata && userinfodata.has_enterprise ? "" : (
                    <div
                      className="AlibabaPuHuiTi-Medium rowCenter skew-golden"
                      onClick={() => this.goJump("/authentication")}
                      style={{ visibility: has_enterprise === true ? "hidden" : "inherit" }}
                    >
                      <i></i>企业认证
                    </div>
                    )
                  )}

                  {/* <div
                    className="view-permissions"
                    onClick={() => {
                      this.goRouteJump("/personalcenter");
                    }}
                  >
                    查看创作权益
                    <IconRight />
                  </div> */}
                </div>

                {identity && identity !== "PERSONAL" ? (
                    <div className="cardpaddingR overview-CardR splititem-card">
                      <span className="AlibabaPuHuiTi-Medium">{userinfodata.group_permission !== "PERSONAL" ? "我的创作资源：" : "创作资源：" }</span>
                      <Space
                        split={
                          <Divider type="vertical" className="splititemicon" />
                        }
                      >
                        <div
                          className="splititem"
                          onClick={this.goJump.bind(this, "/myapp")}
                        >
                          <div>
                            应用：
                            <span className="AlibabaPuHuiTi-Medium" style={{color: "#406EFF"}}>
                            {board.my.app_count}
                            </span>
                          </div>
                        </div>

                        <div
                          className="splititems"
                          // className="splititem"
                          // onClick={this.goJump.bind(
                          //   this,
                          //   "/enginefactory/model"
                          // )}
                        >
                          <div>
                            接口：
                            <span className="AlibabaPuHuiTi-Medium" style={{color: "#406EFF"}}>
                              {board.my.api_count}
                            </span>
                          </div>
                        </div>

                      </Space>
                    </div>
                  ) : ""}

                <div className="cardpaddingR overview-CardR">
                  <div className="AlibabaPuHuiTi-Medium rowStart charts-title Regular-title">
                    <i className="expense_icon"></i>账户费用
                  </div>
                  <div className="AlibabaPuHuiTi-Regular rowStart charts-body first-row">
                    <div className="expense-padding"><i className="expense1_icon"></i></div>
                    <div className="expense-row">
                      <div className="expense_num">
                        <span>￥</span>{formatMoney(this.state.available_limit)}
                      </div>
                      <div className="expense_text">
                        可用余额（元）
                      </div>
                    </div>
                  </div>
                  <div className="AlibabaPuHuiTi-Regular rowStart charts-body">
                    <div className="expense-padding"><i className="expense2_icon"></i></div>
                    <div className="expense-row">
                      <div className="expense_num">
                        <span>￥</span>{formatMoney(this.state.credit_limit)}
                      </div>
                      <div className="expense_text">
                        信用余额（元）
                      </div>
                    </div>
                  </div>
                  <Button
                    type="outline"
                    className="charts-body-btn"
                    onClick={this.onRechargen.bind(this)}
                    long
                    style={{
                      height: 40,
                      borderRadius: 10,
                      margin: '21px 0px 0',
                    }}
                  >  
                    立即充值
                  </Button>
                </div>
                <div className="cardpaddingR overview-CardR">
                  <div className="AlibabaPuHuiTi-Medium rowStart charts-title Regular-title">
                    <i className="resource_icon"></i>资源包
                    {no_open}
                  </div>
                  {no_open ? (
                    <div className="no-pageBox">
                    <img src={no_page}></img>
                    <span>暂未开通资源包，点击开通后享有更多服务！</span>
                    </div>
                  ) 
                  : 
                    (
                      <div className="no-pageBox">
                    <img src={no_page}></img>
                    <span>暂未开通资源包，点击开通后享有更多服务！</span>
                    </div>
                    //   packageData.map((item, index) => {
                    //   if (item.status === 1) {
                    //     return (
                    //       <div className="AlibabaPuHuiTi-Regular rowStart charts-body first-row">
                    //         <div className="expense-padding"><i className={item.icon}></i></div>
                    //         <div className="expense-row">
                    //           <div className="expense_num">
                    //             {all_total[item.packageType]}
                    //           </div>
                    //           <div className="expense_text">
                    //             {item.desc}
                    //           </div>
                    //         </div>
                    //       </div>
                    //   );
                    //   }
                    //   return null;
                    // })
                    )
                  }
                  {/* <Button
                    type="outline"
                    className="charts-body-btn"
                    onClick={this.openEngine.bind(this)}
                    long
                    style={{
                      height: 40,
                      borderRadius: 10,
                      margin: '21px 0px 0',
                    }}
                  >  
                    {no_open ? "开通资源包" : "立即购买"}
                  </Button> */}
                  <Button
                    type="dashed"
                    // className="charts-body-btn"
                    long
                    style={{
                      height: 40,
                      borderRadius: 10,
                      margin: '21px 0px 0',
                    }}
                  >  
                    待开放
                  </Button>
                </div>

                <div className="cardpaddingR overview-CardR  overview-CardR-top">
                  <div className="AlibabaPuHuiTi-Medium rowStart charts-title">
                    <i className="heat_icon"></i>智能文档热力榜
                    {/* <img className="bartable_top_img" src={bartable_top}></img> */}
                  </div>

                  {/* <div className="BarEcharts">
                    <BarEcharts data={dataBarEcharts} title="个数" />
                  </div> */}
                  
                  <div className="BarTable">
                    <div className="BarTable-Title">
                      <span className="AlibabaPuHuiTi-Medium">排名</span>
                      <span className="AlibabaPuHuiTi-Medium">功能名称</span>
                      <span className="AlibabaPuHuiTi-Medium">所属应用</span>
                      <span className="AlibabaPuHuiTi-Medium">调用量</span>
                    </div>
                    <div className="BarTable-Content">
                    {hostList.map((item, index) => {
                        return   <div className="BarTable-Content-item">
                                    <span className="AlibabaPuHuiTi-Medium">{index <= 2 ? <i className={`heat_top_icon${index+1} item-top-left`}></i> : index+1}</span>
                                    <span className="AlibabaPuHuiTi-Medium">{item.api_name}</span>
                                    <span className="AlibabaPuHuiTi-Medium" style={{color:'#3D3D3D'}}>智能PPT</span>
                                    <span className="AlibabaPuHuiTi-Medium" style={{color:'#4170FF'}}>{this.formatNumber(item.total)}</span>
                                  </div>
                    })
                    }
            
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </>
        )}
        <OffModel
          Selectoptions={Selectoptions}
          visible={visible2}
          TablLoading={this.state.TablLoading}
          TabscrollLoading={this.state.TabscrollLoading}
          activeTab={this.state.activeTab}
          listoff={this.state.listoff}
          officialList={this.state.officialListTabl}
          setVisible={() => {
            this.setVisible2(false);
          }}
          onClickTab={this.onClickTab.bind(this)}
          fetchData={(currentPage) => this.fetchData(currentPage)}
          goOffdetails={(item) => this.goOffdetails(item)}
        ></OffModel>

        <ModelPopu
          Selectoptions={Selectoptions}
          visible={ModeVisible}
          TablLoading={this.state.TablLoading}
          TabscrollLoading={this.state.TabscrollLoading}
          activeTab={this.state.activeTab}
          listoff={this.state.listoff}
          officialList={this.state.myModelList}
          setVisible={() => {
            this.setVisibleModel(false);
          }}
          onClickTab={this.onClickTabModel.bind(this)}
          fetchData={(currentPage) => this.fetchDataModelPopu(currentPage)}
          goOffdetails={(item) => this.goCreativeModel(item)}
        ></ModelPopu>

        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visible}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deleteapp.bind(this)}
          onCancel={() => this.setVisible(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}
export default Overview;
